import "./Footer.scss";
import React from "react";
import Logo from "../../assets/logos/JGS_LOGO.png";
import InstagramIcon from "../../assets/icons/instragram-icon.svg";
import FacebookIcon from "../../assets/icons/face-book-icon.svg";
import TwitterIcon from "../../assets/icons/twitter-icon.svg";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-alignment">
      <div className="container">
        <div className="main-footer-wrapper">
          <div className="first-child-alignment">
            <div className="logo-alignment">
              <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
            </div>

            <p>
              Welcome to JGS Glass Industries, your one-stop shop for all glass
              needs! Since 1992, we have been a leading force in the glass
              industry, driven by a commitment to excellence and a passion for
              innovation.
            </p>

            <div className="social-alignment">
              <div className="social-icon-box ">
                <img src={InstagramIcon} alt="InstagramIcon" />
              </div>
              <div className="social-icon-box ">
                <img src={FacebookIcon} alt="FacebookIcon" />
              </div>
              <div className="social-icon-box ">
                <img src={TwitterIcon} alt="TwitterIcon" />
              </div>
            </div>
          </div>
          <div className="footer-right-side-alignment">
            <div className="footer-content-alignment">
              <h6 onClick={() => navigate("/products")}>Products</h6>
              <p onClick={() => navigate("/products")}>
                Toughened / Tempered Glass
              </p>
              <p onClick={() => navigate("/products")}>
                Heat Strengthened Glass
              </p>
              <p onClick={() => navigate("/products")}>Bend Glass</p>
              <p onClick={() => navigate("/products")}>Insulated Glass Unit</p>
              <p onClick={() => navigate("/products")}>Laminated Glass</p>
              <p onClick={() => navigate("/products")}>Bullet Proof Glass</p>
              <p onClick={() => navigate("/products")}>Coloured Glass</p>
              <p onClick={() => navigate("/products")}>Ceramic Frit Glass</p>
              <p onClick={() => navigate("/products")}>Heat Soak Test</p>
            </div>
            <div>
              <div className="footer-content-alignment">
                <h6
                  onClick={() => {
                    localStorage.setItem("project", "");
                    navigate("/projects");
                  }}
                >
                  Projects
                </h6>
                <p
                  onClick={() => {
                    localStorage.setItem("project", 0);
                    navigate("/projects");
                  }}
                >
                  Residential
                </p>
                <p
                  onClick={() => {
                    localStorage.setItem("project", 1);
                    navigate("/projects");
                  }}
                >
                  Commercial
                </p>
                <p
                  onClick={() => {
                    localStorage.setItem("project", 2);
                    navigate("/projects");
                  }}
                >
                  Institutional
                </p>
                <p
                  onClick={() => {
                    localStorage.setItem("project", 3);
                    navigate("/projects");
                  }}
                >
                  Leisure
                </p>
              </div>
            </div>
            <div className="footer-content-alignment">
              <h6 onClick={() => navigate("/about-us")}>About us</h6>
              <h6 onClick={() => navigate("/contact-us")}>Contact us</h6>
              <h6 onClick={() => navigate("/smart-glass-calculator")}>
                Smart Glass Calculator
              </h6>
              <h6 onClick={() => navigate("/infrastructure")}>
                Infrastructure
              </h6>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <a href="/">Terms of Use</a>
          <a href="/">Privacy Policy</a>
          <a href="/">© 2006-2024 jgsglass.com, In.</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
