import "./AboutUs.scss";
import React, { useEffect, useState } from "react";
import HomeGlassIcon from "../../assets/icons/home-glass-icon2.svg";
import SettingsGlassIcon from "../../assets/icons/settings-glass-icon2.svg";
import StarGlassIcon from "../../assets/icons/star-glass-icon2.svg";
import UnitGlassIcon from "../../assets/icons/unit-glass-icon2.svg";
import GujaratGurdianCerti from "../../assets/images/GGL.jpg";
import AboutUsBanner from "../../assets/images/aboutUsBanner.jpg";
import JGS1992 from "../../assets/images/jgs-1992.webp";
import JGS1995 from "../../assets/images/jgs-1995.webp";
import JGS2001 from "../../assets/images/jgs-2001.webp";
import JGS2010 from "../../assets/images/jgs-2010.webp";
import JGS2013 from "../../assets/images/jgs-2013.webp";
import JGS2016 from "../../assets/images/jgs-2016.webp";
import JGS2019 from "../../assets/images/jgs-2019.webp";
import JGS2021 from "../../assets/images/jgs-2021.webp";
import JGS2022 from "../../assets/images/jgs-2022.webp";
import JGS2023 from "../../assets/images/jgs-2023.webp";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import JGS_Authorized_Dealer from "../../assets/images/JGS_Authorized_Dealer.jpg";
import JGS_FOSG from "../../assets/images/JGS_FOSG.jpg";
import JGS_Indirect_Taxes from "../../assets/images/JGS_Indirect_Taxes.jpg";
import JGS_Ministry_Of_Finance from "../../assets/images/JGS_Ministry_Of_Finance.jpg";
import JGS_Outstanding_Revenue_Payment from "../../assets/images/JGS_Outstanding_Revenue_Payment.jpg";
import P1 from "../../assets/icons/p1.svg";
import P2 from "../../assets/icons/p2.svg";
import P3 from "../../assets/icons/p3.svg";
import P4 from "../../assets/icons/p4.svg";
import Value1 from "../../assets/icons/quality.svg";
import Value2 from "../../assets/icons/innovation.svg";
import Value3 from "../../assets/icons/earth.svg";
import Value4 from "../../assets/icons/customer.svg";
import AnimatedSection from "../../components/Animation/AnimatedSection";

function AboutUs() {
  const navigate = useNavigate();

  const historyData = [
    {
      year: "1992",
      desc: "Establishment of retail outlet of Jain Glass",
      imageURL: JGS1992,
    },
    {
      year: "1995",
      desc: "Establishment of Value Addition Unit",
      imageURL: JGS1995,
    },
    {
      year: "2001",
      desc: "Establishment of EVA Lamination",
      imageURL: JGS2001,
    },
    {
      year: "2010",
      desc: "Establishment of JAIN GLASS SOLUTIONS TEMPERING UNIT",
      imageURL: JGS2010,
    },
    {
      year: "2013",
      desc: "South Gujarat's 1st IGU Line",
      imageURL: JGS2013,
    },
    {
      year: "2016",
      desc: "Establishment of Trading Unit named JAIN GLASS TRADING Co.",
      imageURL: JGS2016,
    },
    {
      year: "2019",
      desc: "Planning for 2nd Unit With Jumbo Line",
      imageURL: JGS2019,
    },
    {
      year: "2021",
      desc: "Construction Begin for 2nd Unit",
      imageURL: JGS2021,
    },
    {
      year: "2022",
      desc: "Production Begin at New Unit named JGS GLASS INDUSTRIES",
      imageURL: JGS2022,
    },
    {
      year: "2023",
      desc: "South Gujarat's 1st Automatic Lamination Line & Heat Soak Furnace",
      imageURL: JGS2023,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedZoomImage, setSelectedZoomImage] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % historyData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-about-us-wrapper">
      <div className="about-us-content-wrapper">
        <div className="about-us-banner-wrapper">
          <div className="banner-content-wrapper">
            <img className="banner-image" src={AboutUsBanner} alt="" />
            <div className="banner-text-wrapper">
              <div className="banner-header-text">
                <h2 className="welcome-text">JGS Glass Industries</h2>
                <h2 className="welcome-text">About Us</h2>
                <p>A Legacy of Innovation in Glass Solutions</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="about-us-description">
            <p>
              Welcome to JGS Glass Industries, your one-stop shop for all glass
              needs! Since 1992, we have been a leading force in the glass
              industry, driven by a commitment to excellence and a passion for
              innovation.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="our-story-wrapper">
            <div className="about-us-details">
              <h3>Our Story</h3>
              <p>
                Welcome to JGS Glass Industries - Your destination for all your
                architectural glass needs since 2010.
              </p>
              <p>
                But let's rewind a bit. Back in 1992, we dipped our toes into
                the glass game. Fast forward to today, and we've grown into four
                sister companies, offering a one-stop shop for all your glass
                needs.
              </p>
              <p>
                We started small, as a humble glass retail shop. But with
                determination and hard work, we've become a powerhouse in the
                industry.
              </p>
            </div>
            <div className="about-us-grid">
              <div className="about-us-grid-item">
                <div className="icon-wrapper">
                  <img src={HomeGlassIcon} alt="" />
                </div>
                <div className="content-alignment">
                  <p className="content-title">Surat</p>
                  <p className="content-description">
                    Small glass retail shop in surat
                  </p>
                </div>
              </div>

              <div className="about-us-grid-item">
                <div className="icon-wrapper">
                  <img src={SettingsGlassIcon} alt="" />
                </div>
                <div className="content-alignment">
                  <p className="content-title">1995</p>
                  <p className="content-description">
                    Embraced cutting-edge machinery in 1995
                  </p>
                </div>
              </div>

              <div className="about-us-grid-item">
                <div className="icon-wrapper">
                  <img src={UnitGlassIcon} alt="" />
                </div>
                <div className="content-alignment">
                  <p className="content-title">2010</p>
                  <p className="content-description">
                    Birth of Jain Glass Solutions in 2010
                  </p>
                </div>
              </div>

              <div className="about-us-grid-item">
                <div className="icon-wrapper">
                  <img src={StarGlassIcon} alt="" />
                </div>
                <div className="content-alignment">
                  <p className="content-title">2023</p>
                  <p className="content-description">
                    Birth of JGS Glass Industries in 2023
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="our-history-section">
            <div className="container">
              <div className="our-history-alignment">
                <div className="ouur-history-heading">
                  <h2>Our History</h2>
                </div>
                <div className="our-history-grid">
                  <div className="our-history-grid-item">
                    <div className="left-side-alignment">
                      <div className="child-left-side-details-alignment">
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "1992"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(0)}
                          >
                            <span>1992</span>
                          </div>
                        </div>

                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "1995"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(1)}
                          >
                            <span>1995</span>
                          </div>
                        </div>

                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2001"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(2)}
                          >
                            <span>2001</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2010"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(3)}
                          >
                            <span>2010</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2013"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(4)}
                          >
                            <span>2013</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2016"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(5)}
                          >
                            <span>2016</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2019"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(6)}
                          >
                            <span>2019</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2021"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(7)}
                          >
                            <span>2021</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2022"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(8)}
                          >
                            <span>2022</span>
                          </div>
                        </div>
                        <div
                          className={`child-details-alignment ${
                            historyData[currentIndex].year === "2023"
                              ? "active-child-details"
                              : ""
                          }`}
                        >
                          <div className="dot-alignment"></div>
                          <div
                            className="year-alignment"
                            onClick={() => setCurrentIndex(9)}
                          >
                            <span>2023</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="our-history-grid-item">
                    <div className="right-side-alignment">
                      <h4>{historyData[currentIndex].year}</h4>
                      <p>{historyData[currentIndex].desc}</p>

                      <div className="history-img-alignment ">
                        <img
                          src={historyData[currentIndex].imageURL}
                          alt="JGS History"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="glass-processing-wrapper">
            <div className="container">
              <h2 className="about-section-title">
                Pioneers in Glass Processing
              </h2>
              <div className="processing-steps-wrapper">
                <div className="processing-steps-grid">
                  <div className="mobile-view-alignment">
                    <div className="stepper-alignment-number">
                      <div className="step-round-alignment">
                        <div className="child-box-alignmet">
                          <p>1</p>
                        </div>
                      </div>
                      <div className="steper-border"></div>
                    </div>
                    <div className="stepper-alignment-number">
                      <div className="step-round-alignment">
                        <div className="child-box-alignmet">
                          <p>2</p>
                        </div>
                      </div>
                      <div className="steper-border"></div>
                    </div>
                    <div className="stepper-alignment-number">
                      <div className="step-round-alignment">
                        <div className="child-box-alignmet">
                          <p>3</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="child-grid-alignment">
                      <div className="step-title">
                        First Tempering Plant in Surat (2010)
                      </div>
                      <div className="step-description">
                        We became the first tempering plant in Surat, operating
                        24/7 to meet the ever-increasing demand for tempered
                        glass.
                      </div>
                    </div>
                    <div className="child-grid-alignment">
                      <div className="step-title">
                        Introduced Insulated Glass Units (IGUs) in Surat
                      </div>
                      <div className="step-description">
                        JGS Glass was the first to introduce IGUs in Surat,
                        offering superior thermal and acoustic insulation
                        properties.
                      </div>
                    </div>
                    <div className="child-grid-alignment">
                      <div className="step-title">
                        First BIS Certification in Gujarat (2021)
                      </div>
                      <div className="step-description">
                        JGS Glass takes pride in being the first glass
                        processing plant in Gujarat to achieve BIS
                        Certification. This ensures all our products meet the
                        Bureau of Indian Standards' stringent safety glass
                        standards.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="our-values-wrapper">
            <h2 className="about-section-title">Our Values</h2>
            <div className="values-grid">
              <div className="value-grid-item">
                <div className="grid-item-alignment">
                  <div className="grid-img">
                    <img src={Value1} alt="Quality" />
                  </div>
                  <div className="item-content">
                    <h3 className="grid-item-title">Quality</h3>
                    <div className="grid-item-description">
                      We are committed to providing the highest quality glass
                      products and services, utilizing state-of-the-art
                      technology and stringent quality control processes.
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-grid-item">
                <div className="grid-item-alignment">
                  <div className="grid-img">
                    <img src={Value2} alt="Innovation" />
                  </div>
                  <div className="item-content">
                    <h3 className="grid-item-title">Innovation</h3>
                    <div className="grid-item-description">
                      We constantly strive to push boundaries and embrace new
                      technologies, offering cutting-edge glass solutions to
                      meet the evolving needs of our customers
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-grid-item">
                <div className="grid-item-alignment">
                  <div className="grid-img">
                    <img src={Value3} alt="Sustainability" />
                  </div>
                  <div className="item-content">
                    <h3 className="grid-item-title">Sustainability</h3>
                    <div className="grid-item-description">
                      We are dedicated to utilizing eco-friendly materials and
                      techniques, ensuring our products are environmentally
                      responsible.
                    </div>
                  </div>
                </div>
              </div>
              <div className="value-grid-item">
                <div className="grid-item-alignment">
                  <div className="grid-img">
                    <img src={Value4} alt="Customer Satisfaction" />
                  </div>
                  <div className="item-content">
                    <h3 className="grid-item-title">Customer Satisfaction</h3>
                    <div className="grid-item-description">
                      Your satisfaction is our priority. We provide exceptional
                      customer service, expert advice, and dedicated support
                      throughout your project journey.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="our-values-wrapper">
            <h2 className="about-section-title">
              4Ps - Which Makes Us Special
            </h2>
            <div className="four-ps-grid">
              <div className="ps-grid-item">
                <AnimatedSection animationType="flip-right" duration="900">
                  <img src={P1} alt="Perfect Products" />
                </AnimatedSection>
                <h3 className="ps-grid-item-title">
                  Perfect <br /> Products
                </h3>
              </div>
              <div className="ps-grid-item">
                <AnimatedSection animationType="flip-right" duration="900">
                  <img src={P2} alt="Punctual Delivery" />
                </AnimatedSection>
                <h3 className="ps-grid-item-title">
                  Punctual <br />
                  Delivery
                </h3>
              </div>
              <div className="ps-grid-item">
                <AnimatedSection animationType="flip-right" duration="900">
                  <img src={P3} alt="Proper Customer Service" />
                </AnimatedSection>
                <h3 className="ps-grid-item-title">
                  Proper Customer <br /> Service
                </h3>
              </div>
              <div className="ps-grid-item">
                <AnimatedSection animationType="flip-right" duration="900">
                  <img src={P4} alt="Proffering Greener Solutions" />
                </AnimatedSection>
                <h3 className="ps-grid-item-title">
                  Proffering Greener <br /> Solutions
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="social-proof-wrapper">
            <div className="about-section-title">Social Proof</div>
            <div className="social-proof-grid">
              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={GujaratGurdianCerti}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(GujaratGurdianCerti);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Gujarat Guardian Limited Authorized Dealer - 2021</p>
              </div>

              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={JGS_Authorized_Dealer}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(JGS_Authorized_Dealer);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Authorised Dealer</p>
              </div>

              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={JGS_FOSG}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(JGS_FOSG);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Proud Member of Federation of Safety Glass(FOSG)</p>
              </div>

              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={JGS_Indirect_Taxes}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(JGS_Indirect_Taxes);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Indirect Taxes and Custom Conveys</p>
              </div>

              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={JGS_Ministry_Of_Finance}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(JGS_Ministry_Of_Finance);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Ministry Of Finance, Department of Revenue</p>
              </div>

              <div className="social-grid-item">
                <div className="certificate-wrapper">
                  <img
                    src={JGS_Outstanding_Revenue_Payment}
                    alt=""
                    onClick={() => {
                      setSelectedZoomImage(JGS_Outstanding_Revenue_Payment);
                      setOpen(true);
                    }}
                  />
                </div>
                <p>Outstanding Revenue Payment</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="connect-with-us-wrapper">
            <h3>Connect with Us!</h3>
            <div className="connect-with-us-description">
              We are dedicated to utilizing eco-friendly materials and
              techniques, ensuring our products are environmentally responsible.
            </div>
            <button
              className="contact-us-button"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </button>
          </div>
        </div>
      </div>

      <Dialog maxWidth="md" fullWidth="true" open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <CloseIcon
            onClick={handleClose}
            sx={{
              color: "#393184",
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <img
              className="dialog-image"
              style={{
                maxHeight: "580px",
              }}
              src={selectedZoomImage}
              alt=""
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AboutUs;
