import "./Products.scss";
import React, { useEffect, useRef, useState } from "react";
import building from "../../assets/images/building-facade.svg";
import showerDoors from "../../assets/images/shower-doors.svg";
import balustrades from "../../assets/images/balustrades.svg";
import tableTops from "../../assets/images/table-tops.svg";
import strength from "../../assets/images/strength.svg";
import hammer from "../../assets/images/hammer.svg";
import thermal from "../../assets/images/thermal.svg";
import safety from "../../assets/images/safety.svg";
import durability from "../../assets/images/durability.svg";
import warning from "../../assets/images/warning.svg";
import energy from "../../assets/images/energy.svg";
import noise from "../../assets/images/noise.svg";
import hurricane from "../../assets/images/hurricane.svg";
import sun from "../../assets/images/sun.svg";
import govbuilding from "../../assets/images/gov-building.svg";
import steering from "../../assets/images/steering.svg";
import bullet from "../../assets/images/bullet.svg";
import clarity from "../../assets/images/clarity.svg";
import lock from "../../assets/images/lock.svg";
import visibility from "../../assets/images/visibility.svg";
import maintainance from "../../assets/images/maintainance.svg";
import colors from "../../assets/images/colors-layer.svg";
import asthetic from "../../assets/images/asthetic.svg";
import breakage from "../../assets/images/break.svg";
import coin from "../../assets/images/coin.svg";
import architecture from "../../assets/images/glass-layers.svg";
import designArrow from "../../assets/images/design-arrow.svg";
import daylighting from "../../assets/images/daylighting.svg";
import { CSSTransition } from "react-transition-group";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Toughened from "../../assets/images/toughen-tempered.jpg";
import HeatStrengthened from "../../assets/images/heat-strengthened.jpg";
import Laminated from "../../assets/images/laminated.jpg";
import DGU from "../../assets/images/dgu.jpg";
import BulletProof from "../../assets/images/bullet-proof.jpg";
import CeramicFrit from "../../assets/images/ceramic-frit.jpg";
import Coloured from "../../assets/images/coloured.jpg";
import HeatSoak from "../../assets/images/heat-soak.jpg";
import BentGlass from "../../assets/images/bent.jpg";
import bannerVideo from "../../assets/videos/product.mp4";
import brokenGlass from "../../assets/images/broken-glass.jpg";
import AnimatedSection from "../../components/Animation/AnimatedSection";
import Window from "../../assets/images/window-partition.svg";
import glass from "../../assets/images/glass-partition.svg";
import museum from "../../assets/images/musuem.svg";
import forceEntry from "../../assets/images/force-entry.svg";
import canopy from "../../assets/images/canopy.svg";
import entryDoors from "../../assets/images/entrance.svg";
import commercial from "../../assets/images/commercials.svg";
import { useNavigate } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useMediaQuery } from "@mui/material";

function Products() {
  const navigate = useNavigate();
  const [expandedSection, setExpandedSection] = useState(null);
  const [showScroll, setShowScroll] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");

  const toughenedRef = useRef(null);
  const heatStrengthenedRef = useRef(null);
  const dguRef = useRef(null);
  const laminatedRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 120,
      behavior: "smooth",
    });
  };

  const toggleSection = (sectionId) => {
    setExpandedSection(expandedSection === sectionId ? null : sectionId);
  };

  const scrollTop = () => {
    window.scrollTo({
      top: toughenedRef.current.offsetTop - 120,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (!showScroll && window.pageYOffset > 2200) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 2200) {
        setShowScroll(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        if (!showScroll && window.pageYOffset > 2200) {
          setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 2200) {
          setShowScroll(false);
        }
      });
    };
  }, [showScroll]);

  return (
    <div className="main-product-wrapper">
      <div className="product-page-content-wrapper">
        <div className="product-banner-wrapper">
          <div className="banner-content-wrapper">
            <div className="banner-video-wrapper">
              <video autoPlay loop muted id="productvideo">
                <source src={bannerVideo} type="video/mp4" />
              </video>
            </div>
            <div className="banner-text-wrapper">
              <div className="banner-header-text">
                <h2 className="welcome-text">
                  Elevate Your Space with Our Glass Solutions
                </h2>
                <p>
                  Every glass serves a unique purpose in the world of
                  architecture and interiors.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="product-after-banner-content">
          <div className="product-description">
            <div className="container">
              <div className="product-box-alignment">
                {isMobile ? (
                  <img src={brokenGlass} alt="Heat Strengthened Glass" />
                ) : (
                  <AnimatedSection animationType="fade" duration="1000">
                    <img src={brokenGlass} alt="Heat Strengthened Glass" />
                  </AnimatedSection>
                )}
              </div>
              <p>Decision to choose the wrong glass is irreversible</p>
            </div>
          </div>

          <div className="container">
            <div className="product-details-wrapper">
              <p className="product-find-title">
                Let us find you the best glass
              </p>
              <div className="product-images-wrapper">
                <div className="product-image-grid">
                  <div className="product-image-grid-item">
                    <div className="productImg">
                      <AnimatedSection animationType="zoom" duration="1000">
                        <img src={Toughened} alt="Toughened/Tempered Glass" />
                      </AnimatedSection>
                    </div>
                    <div className="productButton">
                      <button onClick={() => scrollToRef(toughenedRef)}>
                        Toughened/Tempered Glass
                      </button>
                    </div>
                  </div>
                  <div className="product-image-grid-item">
                    <div className="productImg">
                      <AnimatedSection animationType="zoom" duration="1000">
                        <img
                          src={HeatStrengthened}
                          alt="Heat Strengthened Glass"
                        />
                      </AnimatedSection>
                    </div>
                    <div className="productButton">
                      <button onClick={() => scrollToRef(heatStrengthenedRef)}>
                        Heat Strengthened Glass
                      </button>
                    </div>
                  </div>
                  <div className="product-image-grid-item">
                    <div className="productImg">
                      <AnimatedSection animationType="zoom" duration="1000">
                        <img src={DGU} alt="DGUs/IGUs (Argon Filled)" />
                      </AnimatedSection>
                    </div>
                    <div className="productButton">
                      <button onClick={() => scrollToRef(dguRef)}>
                        DGUs/IGUs (Argon Filled)
                      </button>
                    </div>
                  </div>
                  <div className="product-image-grid-item">
                    <div className="productImg">
                      <AnimatedSection animationType="zoom" duration="1000">
                        <img src={Laminated} alt="Laminated Glass" />
                      </AnimatedSection>
                    </div>
                    <div className="productButton">
                      <button onClick={() => scrollToRef(laminatedRef)}>
                        Laminated Glass
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="product-other-details-section">
          <div className="container">
            <div className="product-other-details-alignment" ref={toughenedRef}>
              <div className="heading-alignment">
                <h2>Toughened / Tempered Glass</h2>
                <p>
                  Undergoes a thermal process where glass is heated, then
                  rapidly cooled. Creates a 4-5 times strength as compared to
                  annealed glass. This process enhances its resistance to impact
                  any thermal stress. Its superior strength & safety makes it a
                  popular choice for both residential & commercial projects.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={Toughened} alt="Toughened/Tempered Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for high-stress environments like</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>Building Facades</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={showerDoors} alt="" />
                            </div>
                            <p>Shower Doors</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={tableTops} alt="" />
                            </div>
                            <p>Table Tops</p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 1}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={strength} alt="" />
                                </div>
                                <p>
                                  Enhanced <br />
                                  strength
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={hammer} alt="" />
                                </div>
                                <p>
                                  High impact <br />
                                  resistance
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={thermal} alt="" />
                                </div>
                                <p>
                                  Thermal <br />
                                  stress resistance
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun Fact</h3>
                            <p>It breaks into small, smooth-edged pieces</p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>
                                  Improved <br />
                                  safety
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={warning} alt="" />
                                </div>
                                <p>
                                  Reduced risk <br />
                                  from injury
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine your building is facing a severe storm &
                              blackout. With no way to call for help. <br /> or
                              <br />
                              Imagine your kitchen glass collides with a giant
                              utensil & shatters.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(1)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 1 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 1 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="product-other-details-alignment"
              ref={heatStrengthenedRef}
            >
              <div className="heading-alignment">
                <h2>Heat Strengthened Glass</h2>
                <p>
                  Undergoes a thermal process where glass is heated to a lower
                  temperature compared to Toughened/Tempered Glass and then
                  cooled, resulting in increased strength. However, it is not as
                  strong as Toughened Glass.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={HeatStrengthened} alt="Heat Strengthened Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for areas requiring improved strength</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>Glass facades</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={balustrades} alt="" />
                            </div>
                            <p>Balustrades</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={tableTops} alt="" />
                            </div>
                            <p>Table Tops</p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 2}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={strength} alt="" />
                                </div>
                                <p>
                                  Increased <br />
                                  strength
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={thermal} alt="" />
                                </div>
                                <p>
                                  Thermal <br />
                                  stress resistance
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={durability} alt="" />
                                </div>
                                <p>Durability</p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              When broken, it forms larger and sharper pieces
                              compared to the toughen glass.
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>
                                  Enhanced <br />
                                  safety
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={warning} alt="" />
                                </div>
                                <p>
                                  Reduced risk <br />
                                  from injury
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine a heavy object accidentally falls on your
                              glass tabletop during a family gathering. <br />{" "}
                              or
                              <br /> Imagine strong winds causing your balcony
                              glass to crack and weaken.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(2)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 2 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 2 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment">
              <div className="heading-alignment">
                <h2>Bend Glass</h2>
                <p>
                  Bend Glass is created by heating flat glass to a softening
                  point, then carefully shaping it into a desired convex or
                  concave form. This process results in a durable and visually
                  striking curved glass product.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={BentGlass} alt="Bend Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for design-forward applications like</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={architecture} alt="" />
                            </div>
                            <p>
                              Architectural <br />
                              elements
                            </p>
                          </div>

                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={Window} alt="" />
                            </div>
                            <p>Window Partitions</p>
                          </div>

                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>
                              Building <br />
                              facades
                            </p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 7}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <img src={asthetic} alt="" />
                                <p>
                                  Aesthetic <br /> appeal
                                </p>
                              </div>

                              <div className="all-details-content">
                                <img src={strength} alt="" />
                                <p>
                                  Enhanced <br /> strength
                                </p>
                              </div>

                              <div className="all-details-content">
                                <img src={energy} alt="" />
                                <p>
                                  Energy <br /> efficiency
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>Not only career, but glass also takes shape.</p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={designArrow} alt="" />
                                </div>
                                <p>
                                  Design <br />
                                  versatility
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={daylighting} alt="" />
                                </div>
                                <p>
                                  Daylighting <br />
                                  benefits
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine living or working in a space with bland,
                              uninspiring design elements that fail to make an
                              impression.
                              <br />
                              or <br />
                              Imagine dealing with sun-damaged interiors and
                              furnishings due to harmful UV rays penetrating
                              through your windows.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(7)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 7 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 7 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment" ref={dguRef}>
              <div className="heading-alignment">
                <h2>DGUs/IGUs (Argon Filled)</h2>
                <p>
                  DGUs/IGUs (Double Glazed Units/Insulated Glass Units) consist
                  of two or more glass panes separated by a spacer filled with
                  argon gas. The edges are sealed to create an airtight unit.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={DGU} alt="DGUs/IGUs (Argon Filled)" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>
                          Ideal for energy-efficient buildings and noise
                          reduction
                        </h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={glass} alt="" />
                            </div>
                            <p>Glass facades</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>Building Facades</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={Window} alt="" />
                            </div>
                            <p>Window</p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 3}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={thermal} alt="" />
                                </div>
                                <p>
                                  Enhanced thermal <br />
                                  insulation
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={energy} alt="" />
                                </div>
                                <p>
                                  Energy <br />
                                  efficiency
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              In a modern eco-friendly home, argon-filled
                              insulated glass units keep interiors cozy during
                              winter while reducing energy consumption
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={energy} alt="" />
                                </div>
                                <p>
                                  Improved energy <br />
                                  efficiency
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={noise} alt="" />
                                </div>
                                <p>
                                  Noise <br />
                                  reduction
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine living in a cold home, with high heating
                              bills and a constant chill in the air. <br /> or
                              <br />
                              Imagine trying to relax in a noisy urban
                              apartment, with the sounds of traffic and
                              neighbors interrupting your peace
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(3)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 3 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 3 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment" ref={laminatedRef}>
              <div className="heading-alignment">
                <h2>Laminated Glass</h2>
                <p>
                  Laminated Glass is created by sandwiching a layer of polyvinyl
                  butyral (PVB) or ethylene-vinyl acetate (EVA) between two
                  layers of glass. These layers are then fused together through
                  heat and pressure, resulting in a strong and resilient
                  composite.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={Laminated} alt="Laminated Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for prioritising</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={noise} alt="" />
                            </div>
                            <p>
                              Safety and sound <br />
                              insulation
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={hurricane} alt="" />
                            </div>
                            <p>
                              Used in <br />
                              hurricane-prone
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>
                              Building <br />
                              Facades
                            </p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 4}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>
                                  Excellent <br />
                                  safety
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={noise} alt="" />
                                </div>
                                <p>
                                  Sound
                                  <br />
                                  insulation
                                </p>
                              </div>

                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={sun} alt="" />
                                </div>
                                <p>
                                  UV
                                  <br />
                                  protection
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              In coastal areas, laminated glass windows protect
                              homes from strong winds and flying debris during a
                              hurricane.
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>
                                  Enhanced <br />
                                  safety
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={noise} alt="" />
                                </div>
                                <p>
                                  Noise <br /> reduction
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine being caught in a severe storm, with
                              strong winds and thieves threatening to break
                              through your windows. <br /> or <br /> Imagine
                              living in a noisy neighbourhood, struggling to
                              find peace and quiet in your own home.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(4)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 4 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 4 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment">
              <div className="heading-alignment">
                <h2>Bulletproof Glass</h2>
                <p>
                  Bulletproof Glass is manufactured using multiple layers of
                  glass and polycarbonate, often referred to as "glass-clad
                  polycarbonate." These layers are bonded together under heat
                  and pressure to create a highly resistant composite material.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={BulletProof} alt="Bulletproof Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for prioritising</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={govbuilding} alt="" />
                            </div>
                            <p>
                              Government <br />
                              buildings
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={steering} alt="" />
                            </div>
                            <p>
                              VIP <br />
                              hurricane-prone
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={museum} alt="" />
                            </div>
                            <p>Museum</p>
                          </div>
                        </div>
                      </div>
                      <CSSTransition
                        in={expandedSection === 4}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={bullet} alt="" />
                                </div>
                                <p>
                                  Bullet <br />
                                  resistance
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={forceEntry} alt="" />
                                </div>
                                <p>
                                  Exceptional resistance to <br />
                                  force entry
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={clarity} alt="" />
                                </div>
                                <p>
                                  Provide protection without
                                  <br />
                                  compromising visibility
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              Bunch of laminated glass with specific TPU
                              interlayer
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={lock} alt="" />
                                </div>
                                <p>
                                  Utmost <br />
                                  security
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={visibility} alt="" />
                                </div>
                                <p>
                                  Maintained <br /> visibility
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine being a high-profile individual,
                              constantly facing potential threats to your safety
                              and security. <br /> or <br /> Imagine working in
                              a government building, where maintaining a secure
                              environment is of utmost importance
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(4)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 4 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 4 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment">
              <div className="heading-alignment">
                <h2>Coloured Glass</h2>
                <p>
                  Coloured Glass is created by adding metal oxides or other
                  pigments to the glass batch. It is then melted and cooled
                  according to specific temperature profiles to produce
                  different colours and effects.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={Coloured} alt="Coloured Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>
                          Ideal for versatile design options for building
                          facades and decorative features
                        </h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={building} alt="" />
                            </div>
                            <p>
                              Building <br />
                              facades
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={glass} alt="" />
                            </div>
                            <p>
                              Glass <br />
                              Partitions
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={commercial} alt="" />
                            </div>
                            <p>
                              Commercial <br />
                              Space
                            </p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 6}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={colors} alt="" />
                                </div>
                                <p>
                                  Wide range of <br />
                                  colours
                                </p>
                              </div>

                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={sun} alt="" />
                                </div>
                                <p>
                                  UV <br /> protection
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={clarity} alt="" />
                                </div>
                                <p>
                                  High <br />
                                  clarity
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>Multiple colors to play around</p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={asthetic} alt="" />
                                </div>
                                <p>
                                  Enhanced <br />
                                  aesthetics
                                </p>
                              </div>

                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={maintainance} alt="" />
                                </div>
                                <p>
                                  Easy <br />
                                  maintenance
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine living or working in a space with bland,
                              uninspiring design elements that fail to make an
                              impression.
                              <br /> or
                              <br /> Imagine dealing with sun-damaged interiors
                              and furnishings due to harmful UV rays penetrating
                              through your windows.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(6)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 6 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 6 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment">
              <div className="heading-alignment">
                <h2>Ceramic Frit Glass</h2>
                <p>
                  Ceramic Frit Glass is created by fusing a ceramic frit layer
                  onto the surface of glass using heat. This process results in
                  a durable and highly resistant surface.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={CeramicFrit} alt="Ceramic Frit Glass" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>
                          Ideal for high-traffic areas like commercial buildings
                        </h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={glass} alt="" />
                            </div>
                            <p>
                              Glass <br />
                              Partitions
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={canopy} alt="" />
                            </div>
                            <p>Canopies</p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={entryDoors} alt="" />
                            </div>
                            <p>
                              Entry <br />
                              Doors
                            </p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 5}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <img src={hammer} alt="" />
                                <p>
                                  High <br />
                                  resistance
                                </p>
                              </div>
                              <div className="all-details-content">
                                <img src={durability} alt="" />
                                <p>Durability</p>
                              </div>
                              <div className="all-details-content">
                                <img src={maintainance} alt="" />
                                <p>
                                  Easy <br /> maintenance
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              Ceramic is a process where glass enamel is fused
                              on the glass surface, it is compulsory to make
                              ceramic, toughen or heat strengthened
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <img src={safety} alt="" />
                                <p>
                                  Customizable design <br />
                                  and patterns
                                </p>
                              </div>
                              <div className="all-details-content">
                                <img src={maintainance} alt="" />
                                <p>
                                  Color <br />
                                  options
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine owning a commercial space with high foot
                              traffic, where the glass surfaces quickly become
                              scratched and worn. <br /> or <br />
                              Imagine dealing with frequent glass replacements
                              and repair costs due to damage and wear and tear.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(5)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 5 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 5 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-other-details-alignment">
              <div className="heading-alignment">
                <h2>Heat Soak</h2>
                <p>
                  Heat Soak is an additional thermal process performed on
                  Toughened Glass to minimise the risk of spontaneous breakage
                  caused by nickel sulphide inclusions. The glass is heated at a
                  high temperature to accelerate the detection and elimination
                  of potential defects.
                </p>
                <div className="enquire-now-button">
                  <button onClick={() => navigate("/contact-us")}>
                    Enquire Now
                  </button>
                </div>
              </div>
              <div className="product-all-content-alignment">
                <div className="content-flex">
                  <div className="content-img-box">
                    <img src={HeatSoak} alt="Heat Soak" />
                  </div>
                  <div className="main-view-more-alignment">
                    <div className="content-view-more-alignment">
                      <div className="content-right-side-alignment">
                        <h3>Ideal for minimising the risk of</h3>
                        <div className="right-content-details-flex">
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={breakage} alt="" />
                            </div>
                            <p>
                              Spontaneous <br />
                              breakage
                            </p>
                          </div>

                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={sun} alt="" />
                            </div>
                            <p>
                              Detection and <br /> elimination (NiS)
                            </p>
                          </div>
                          <div className="all-details-content">
                            <div className="our-milestone-icon">
                              <img src={balustrades} alt="" />
                            </div>
                            <p>Balustrades</p>
                          </div>
                        </div>
                      </div>

                      <CSSTransition
                        in={expandedSection === 7}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                      >
                        <div>
                          <div className="content-right-side-alignment content-first-child-padding">
                            <h3>What does it give?</h3>
                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>Greater safety</p>
                              </div>

                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={hammer} alt="" />
                                </div>
                                <p>
                                  Reduced property <br /> damage
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={maintainance} alt="" />
                                </div>
                                <p>
                                  Easy <br />
                                  maintenance
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Fun fact</h3>
                            <p>
                              Not only cylinder, but glass can explode as well.
                            </p>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>Features</h3>

                            <div className="right-content-details-flex">
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={safety} alt="" />
                                </div>
                                <p>
                                  Enhanced <br />
                                  safety
                                </p>
                              </div>
                              <div className="all-details-content">
                                <div className="our-milestone-icon">
                                  <img src={coin} alt="" />
                                </div>
                                <p>
                                  Cost-effective <br />
                                  alternative
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="content-right-side-alignment">
                            <h3>But, why should you care?</h3>
                            <p>
                              Imagine living or working in a space with bland,
                              uninspiring design elements that fail to make an
                              impression. <br /> or <br />
                              Imagine dealing with sun-damaged interiors and
                              furnishings due to harmful UV rays penetrating
                              through your windows.
                            </p>
                          </div>
                        </div>
                      </CSSTransition>
                    </div>
                    <button
                      onClick={() => toggleSection(7)}
                      style={{
                        margin: "20px 0",
                        padding: "10px 20px",
                        fontSize: "16px",
                      }}
                    >
                      <span>
                        {expandedSection === 7 ? "View Less" : "View More"}
                      </span>

                      {expandedSection === 7 ? (
                        <KeyboardDoubleArrowUpIcon className="down-arrow" />
                      ) : (
                        <KeyboardDoubleArrowDownIcon className="down-arrow" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showScroll && (
        <ArrowUpwardIcon
          className="scrollTop"
          onClick={scrollTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
            backgroundColor: "#393184",
            borderRadius: "50%",
            padding: "10px",
            cursor: "pointer",
            color: "#fff",
          }}
        />
      )}
    </div>
  );
}

export default Products;
