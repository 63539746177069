import "./Header.scss";
import React, { useEffect, useState } from "react";
import JGSLogo from "../../assets/logos/JGS_LOGO.png";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function Header() {
  const location = useLocation();
  const [isSelected, setIsSelected] = useState("home");
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    setIsSelected(window.location.pathname.split("/")[1]);
  }, [location]);

  return (
    <>
      <div className="main-header-wrapper">
        <div className="container">
          <div className="main-header-flex">
            <div className="main-logo-wrapper">
              <Link to="/">
                <img src={JGSLogo} alt="JGS" />
              </Link>
            </div>
            <div className="main-links-wrapper">
              <Link
                to="/about-us"
                className={`${isSelected === "about-us" ? "active" : ""}`}
                onClick={() => setIsSelected("about-us")}
              >
                About Us
              </Link>
              <Link
                to="/smart-glass-calculator"
                className={`${
                  isSelected === "smart-glass-calculator" ? "active" : ""
                }`}
                onClick={() => setIsSelected("smart-glass-calculator")}
              >
                Smart Glass Calculator
              </Link>
              <Link
                to="/infrastructure"
                className={`${isSelected === "infrastructure" ? "active" : ""}`}
                onClick={() => setIsSelected("infrastructure")}
              >
                Infrastructure
              </Link>
              <Link
                to="/products"
                className={`${isSelected === "products" ? "active" : ""}`}
                onClick={() => setIsSelected("products")}
              >
                Products
              </Link>
              <Link
                to="/projects"
                className={`${isSelected === "projects" ? "active" : ""}`}
                onClick={() => setIsSelected("projects")}
              >
                Projects
              </Link>
              <Link
                to="/contact-us"
                className={`${isSelected === "contact-us" ? "active" : ""}`}
                onClick={() => setIsSelected("contact-us")}
              >
                Contact
              </Link>
            </div>
            <div className="main-mobile-menu">
              <MenuIcon
                sx={{
                  fontSize: "36px",
                  color: "#393184",
                  cursor: "pointer",
                }}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
            </div>
          </div>
        </div>
      </div>
      {showMobileMenu && (
        <div id="mobile-menu" class="overlay">
          <div className="menu-content-alignment">
            <div className="icon-alignment">
              <CloseIcon
                className="closebtn"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
            </div>
            <div class="overlay-content">
              <Link
                to="/about-us"
                onClick={() => {
                  setIsSelected("about-us");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                About Us
              </Link>
              <Link
                to="/smart-glass-calculator"
                onClick={() => {
                  setIsSelected("smart-glass-calculator");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Smart Glass Calculator
              </Link>
              <Link
                to="/infrastructure"
                onClick={() => {
                  setIsSelected("infrastructure");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Infrastructure
              </Link>
              <Link
                to="/products"
                onClick={() => {
                  setIsSelected("products");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Products
              </Link>
              <Link
                to="/projects"
                onClick={() => {
                  setIsSelected("projects");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Projects
              </Link>
              <Link
                to="/contact-us"
                onClick={() => {
                  setIsSelected("contact-us");
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
