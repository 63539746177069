import React, { useEffect, useState } from "react";
import "./NorthFacade.scss";
import {
  Checkbox,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  neutralAppearance,
  blueAppearance,
  metalAppearance,
  greenAppearance,
  silverAppearance,
} from "../../../constants/appearance";

const SHGCConstant = 0.834;
const UValueConstant = 5.73;
const ACEfficiency = 0.25;
const dimensionConstant = 0.3048;

const uValue = {
  4: [5.75, 2.69, 1.76],
  5: [5.72, 2.68, 1.75],
  6: [5.69, 2.66, 1.74],
  7: [5.47, 2.64, 1.74],
  8: [5.62, 2.64, 1.72],
  9: [5.41, 2.62, 1.73],
  10: [5.56, 2.61, 1.7],
  11: [5.35, 2.6, 1.71],
  12: [5.5, 2.58, 1.69],
  13: [5.3, 2.58, 1.7],
  15: [5.41, 2.54, 1.66],
  17: [5.19, 2.54, 1.68],
  21: [5.08, 2.5, 1.66],
  25: [4.98, 2.46, 1.64],
  31: [4.84, 2.41, 1.6],
};

const dimensionSHGCIntensity = {
  south: {
    singleSHGC: 0.85,
    dguSHGC: 0.65,
    avgSolarIntensity: 1100,
    changeCoefficient: 1.3,
  },
  west: {
    singleSHGC: 0.8,
    dguSHGC: 0.6,
    avgSolarIntensity: 900,
    changeCoefficient: 1.333,
  },
  east: {
    singleSHGC: 0.8,
    dguSHGC: 0.6,
    avgSolarIntensity: 800,
    changeCoefficient: 1.333,
  },
  north: {
    singleSHGC: 0,
    dguSHGC: 0,
    avgSolarIntensity: 0,
    changeCoefficient: 0,
  },
};

function NorthFacade({
  formData,
  secondLeftValue,
  setSeoncdLeftValue,
  secondTopValue,
  setSecondTopValue,
  firstLeftValue,
  setFirstLeftValue,
  firstTopValue,
  setFirstTopValue,
  setLayer,
}) {
  const [glassValueData, setGlassValueData] = useState({
    outerPane: {
      totalThickness: 0,
      isLaminated: false,
      glass1Thickness: 0,
      interlayerThickness: 1.52,
      glass2Thickness: 0,
    },
    middlePane: {
      totalThickness: 0,
      isLaminated: false,
      glass1Thickness: 0,
      interlayerThickness: 1.52,
      glass2Thickness: 0,
    },
    innerPane: {
      totalThickness: 0,
      isLaminated: false,
      glass1Thickness: 0,
      interlayerThickness: 1.52,
      glass2Thickness: 0,
    },
  });
  const [selectedSLCode, setSelectedSLCode] = useState({});
  const [companies, setCompanies] = useState(neutralAppearance);
  const [selectedLayer, setSelectedLayer] = useState("Single");
  const [selectedAppearance, setSelectedAppearance] = useState("Neutral");
  const [conversionUnit, setConversionUnit] = useState("mm");
  const [outerLaminated, setOuterLaminated] = useState(false);
  const [middleLaminated, setMiddleLaminated] = useState(false);
  const [innerLaminated, setInnerLaminated] = useState(false);
  const [savingsInDGU1, setSavingsInDGU1] = useState(0);
  const [savingsInDGU2, setSavingsInDGU2] = useState(0);
  const [averagePerMonthSaving, setAveragePerMonthSaving] = useState(0);
  const [averagePerMonthSavingSGU, setAveragePerMonthSavingSGU] = useState(0);
  const [inputCount, setInputCount] = useState(1);
  const [dimensions, setDimensions] = useState([{ height: "", width: "" }]);
  const [totalHeight, setTotalHeight] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);

  const fetchAppearance = (appearance) => {
    setSelectedSLCode({});
    setSelectedAppearance(appearance);
    if (appearance === "Blue") {
      setCompanies(blueAppearance);
    }
    if (appearance === "Metal") {
      setCompanies(metalAppearance);
    }
    if (appearance === "Neutral") {
      setCompanies(neutralAppearance);
    }
    if (appearance === "Green") {
      setCompanies(greenAppearance);
    }
    if (appearance === "Silver") {
      setCompanies(silverAppearance);
    }

    // axios
    //   .post(
    //     "http://localhost:3001/api/companies/appearance",
    //     {
    //       appearance: appearance,
    //     },
    //     {
    //       headers: {
    //         "ngrok-skip-browser-warning": "true",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     setCompanies(response.data.data.companies);
    //   })
    //   .catch((error) => {
    //     setCompanies([]);
    //     console.log("error", error);
    //   });
  };

  const handleSLCodeChange = (slCode) => {
    const selectedCompany = companies.find(
      (company) => company.code === slCode
    );
    setSelectedSLCode(selectedCompany);
  };

  const handleTotalThicknessCalculation = (pane, checkedValue) => {
    if (pane === "outer") {
      if (checkedValue) {
        setGlassValueData((prev) => ({
          ...prev,
          outerPane: {
            ...prev.outerPane,
            totalThickness:
              parseFloat(
                Number(prev.outerPane.glass1Thickness) +
                  Number(prev.outerPane.interlayerThickness) +
                  Number(prev.outerPane.glass2Thickness)
              ).toFixed(2) || 0,
          },
        }));
      } else {
        setGlassValueData((prev) => ({
          ...prev,
          outerPane: {
            ...prev.outerPane,
            totalThickness: 6,
            glass1Thickness: 6,
            glass2Thickness: 6,
          },
        }));
      }
    }
    if (pane === "middle") {
      if (checkedValue) {
        setGlassValueData((prev) => ({
          ...prev,
          middlePane: {
            ...prev.middlePane,
            totalThickness:
              parseFloat(
                Number(prev.middlePane.glass1Thickness) +
                  Number(prev.middlePane.interlayerThickness) +
                  Number(prev.middlePane.glass2Thickness)
              ).toFixed(2) || 0,
          },
        }));
      } else {
        setGlassValueData((prev) => ({
          ...prev,
          middlePane: {
            ...prev.middlePane,
            totalThickness: 6,
            glass1Thickness: 6,
            glass2Thickness: 6,
          },
        }));
      }
    }
    if (pane === "inner") {
      if (checkedValue) {
        setGlassValueData((prev) => ({
          ...prev,
          innerPane: {
            ...prev.innerPane,
            totalThickness:
              parseFloat(
                Number(prev.innerPane.glass1Thickness) +
                  Number(prev.innerPane.interlayerThickness) +
                  Number(prev.innerPane.glass2Thickness)
              ).toFixed(2) || 0,
          },
        }));
      } else {
        setGlassValueData((prev) => ({
          ...prev,
          innerPane: {
            ...prev.innerPane,
            totalThickness: 6,
            glass1Thickness: 6,
            glass2Thickness: 6,
          },
        }));
      }
    }
  };

  const calculateMinSHGC = () => {
    return Math.min(
      selectedSLCode.shgc_sf ? Number(selectedSLCode.shgc_sf) : SHGCConstant,
      SHGCConstant,
      dimensionSHGCIntensity.north.dguSHGC
    );
  };

  const calculateResultantSC = () => {
    if (Number(selectedSLCode.shadingCoefficient) * 100 > 0) {
      return Number(selectedSLCode.shadingCoefficient);
    } else {
      return 1;
    }
  };

  const calculateAvgTempDiff = () => {
    return Number(formData.exteriorTemp) - Number(formData.interiorTemp);
  };

  const calculateResultantHeatGain = () => {
    const layerIndex =
      selectedLayer === "Single" ? 0 : selectedLayer === "Double" ? 1 : 2;
    const selectedUValue =
      Number(selectedSLCode.u_value) > 0
        ? Number(selectedSLCode.u_value)
        : UValueConstant;

    const innerUValue =
      Number(glassValueData.innerPane.totalThickness) > 0
        ? uValue[Math.floor(Number(glassValueData.innerPane.totalThickness))][
            layerIndex
          ]
        : UValueConstant;

    const outerUValue =
      Number(glassValueData.outerPane.totalThickness) > 0
        ? uValue[Math.floor(Number(glassValueData.outerPane.totalThickness))][
            layerIndex
          ]
        : UValueConstant;

    const resultantUValue = Math.min(
      selectedUValue,
      outerUValue,
      innerUValue,
      UValueConstant
    );

    return (
      dimensionSHGCIntensity.north.avgSolarIntensity *
        calculateMinSHGC() *
        calculateResultantSC() +
      resultantUValue * calculateAvgTempDiff()
    );
  };

  const calculateResultantHeatGainDGU = () => {
    const layerIndex =
      selectedLayer === "Single" ? 0 : selectedLayer === "Double" ? 1 : 2;
    const selectedUValue =
      Number(selectedSLCode.u_value) > 0
        ? Number(selectedSLCode.u_value)
        : UValueConstant;

    const innerUValue =
      Number(glassValueData.innerPane.totalThickness) > 0
        ? uValue[Math.floor(Number(glassValueData.innerPane.totalThickness))][
            layerIndex
          ]
        : UValueConstant;

    const outerUValue =
      Number(glassValueData.outerPane.totalThickness) > 0
        ? uValue[Math.floor(Number(glassValueData.outerPane.totalThickness))][
            layerIndex
          ]
        : UValueConstant;

    const resultantUValue = Math.min(
      selectedUValue,
      outerUValue,
      innerUValue,
      UValueConstant
    );

    return (
      dimensionSHGCIntensity.north.avgSolarIntensity *
        1.3 *
        calculateMinSHGC() *
        calculateResultantSC() +
      resultantUValue * 1.5 * calculateAvgTempDiff()
    );
  };

  const calculateTotalDimensionValue = () => {
    return (
      totalWidth *
      dimensionConstant *
      totalHeight *
      dimensionConstant *
      inputCount
    );
  };

  const calculateSavingsInDGU = () => {
    let savingsValue = 0;
    let savingsValueDGU = 0;
    let avgPerMonthSavingsValue = 0;
    let avgPerMonthSavingsValueSGU = 0;
    if (Number(glassValueData.outerPane.totalThickness) * 1000 > 0) {
      const resultantHeatGain = calculateResultantHeatGain();
      const resultantHeatGainDGU = calculateResultantHeatGainDGU();

      savingsValue =
        (((resultantHeatGain * calculateTotalDimensionValue()) / ACEfficiency) *
          Number(formData.electricityCost) *
          Number(formData.hrsACPerDay) *
          Number(formData.hrsACPerAnnum)) /
        1000;

      savingsValueDGU =
        ((((resultantHeatGainDGU - resultantHeatGain) *
          calculateTotalDimensionValue()) /
          ACEfficiency) *
          Number(formData.electricityCost) *
          Number(formData.hrsACPerDay) *
          Number(formData.hrsACPerAnnum)) /
        1000;

      avgPerMonthSavingsValue =
        savingsValue / (Number(formData.hrsACPerAnnum) / 30);
      avgPerMonthSavingsValueSGU =
        savingsValueDGU / (Number(formData.hrsACPerAnnum) / 30);

      setSavingsInDGU1(parseFloat(savingsValue).toFixed(2) || 0);
      setSavingsInDGU2(parseFloat(savingsValueDGU).toFixed(2) || 0);

      setAveragePerMonthSavingSGU(
        parseFloat(avgPerMonthSavingsValueSGU).toFixed(2) || 0
      );
      setAveragePerMonthSaving(
        parseFloat(avgPerMonthSavingsValue).toFixed(2) || 0
      );
    } else {
      setSavingsInDGU1(0);
      setSavingsInDGU2(0);
      setAveragePerMonthSavingSGU(0);
      setAveragePerMonthSaving(0);
    }
  };

  const handleInputCountChange = (e) => {
    const newCount = Number(e.target.value);
    setInputCount(newCount);
    setDimensions(Array(newCount).fill({ height: "", width: "" }));
  };

  const handleDimensionChange = (index, dimension, value) => {
    const numericValue = Number(value);
    if (numericValue < 0) {
      // Prevent negative values
      return;
    }
    const newDimensions = [...dimensions];
    newDimensions[index] = {
      ...newDimensions[index],
      [dimension]: Number(value),
    };
    setDimensions(newDimensions);
  };

  useEffect(() => {
    const totalH = dimensions.reduce(
      (acc, curr) => acc + (Number(curr.height) || 0),
      0
    );
    const totalW = dimensions.reduce(
      (acc, curr) => acc + (Number(curr.width) || 0),
      0
    );
    setTotalHeight(totalH);
    setTotalWidth(totalW);
  }, [dimensions]);

  useEffect(() => {
    console.log("glassValueData", glassValueData);
  }, []);

  useEffect(() => {
    console.log("selectedSLCode", selectedSLCode);
  }, [selectedSLCode]);

  return (
    <div className="top-form-alignment">
      <div className="step-grid">
        <div className="step-input-alignment">
          <FormLabel>
            Select Appearance <br />
            &nbsp;&nbsp;
          </FormLabel>

          <div className="input-details">
            <select
              value={selectedAppearance || "Neutral"}
              onChange={(e) => fetchAppearance(e.target.value)}
            >
              <option value="Blue">Blue</option>
              <option value="Metal">Metal</option>
              <option value="Neutral" selected>
                Neutral
              </option>
              <option value="Green">Green</option>
              <option value="Silver">Silver</option>
            </select>
          </div>
        </div>

        <div className="step-input-alignment">
          <FormLabel>Select Outer Panel Reflective Glass SL Code</FormLabel>

          <div className="input-details">
            <select
              value={selectedSLCode.code || ""}
              onChange={(e) => handleSLCodeChange(e.target.value)}
            >
              <option value="" disabled></option>
              {companies?.map((company, index) => (
                <option key={index} value={company.code}>
                  <span>{company.code}</span>
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          marginTop: "10px",
        }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#e8e6ff",
              }}
            >
              <TableCell align="center">VLT%</TableCell>
              <TableCell align="center">Ext Refl</TableCell>
              <TableCell align="center">Int Refl</TableCell>
              <TableCell align="center">SHGC</TableCell>
              <TableCell align="center">SC</TableCell>
              <TableCell align="center">U Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell align="center">
                {selectedSLCode.lightTransmissionPerc || 0}
              </TableCell>
              <TableCell align="center">
                {selectedSLCode.externalReflectionPerc || 0}
              </TableCell>
              <TableCell align="center">
                {selectedSLCode.internalReflectionPerc || 0}
              </TableCell>
              <TableCell align="center">
                {selectedSLCode.shgc_sf || 0}
              </TableCell>
              <TableCell align="center">
                {selectedSLCode.shadingCoefficient || 0}
              </TableCell>
              <TableCell align="center">
                {selectedSLCode.u_value || 0}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell align="center">
                {selectedSLCode.company || "-"}
              </TableCell>
              <TableCell
                align="center"
                colSpan={3}
                sx={{
                  borderLeft: "1px solid #e0e0e0 !important",
                  borderRight: "1px solid #e0e0e0 !important",
                }}
              >
                {selectedSLCode.colourShade || "-"}
              </TableCell>
              <TableCell align="center" colSpan={2}>
                {selectedSLCode.code || "-"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="step-grid">
        <div className="step-input-alignment">
          <FormLabel>Select Number of Glass Layers</FormLabel>

          <div className="input-details">
            <select
              onChange={(e) => {
                setSelectedLayer(e.target.value);
                setLayer(e.target.value);
              }}
            >
              <option value="Single">Single</option>
              <option value="Double">Double</option>
              <option value="Triple">Triple</option>
            </select>
          </div>
        </div>
        {/* <div className="step-input-alignment">
          <FormLabel>Conversion</FormLabel>

          <div className="input-details">
            <RadioGroup
              name="radio-buttons-group"
              className="radio-btn-group"
              value={conversionUnit}
            >
              <FormControlLabel
                value="ft"
                control={<Radio />}
                label="mm To ft."
                onChange={() => {
                  setConversionUnit("ft");
                  // mmToFt();
                }}
              />
              <FormControlLabel
                value="mm"
                control={<Radio />}
                label="ft. To mm"
                onChange={() => {
                  setConversionUnit("mm");
                  // ftToMm();
                }}
              />
            </RadioGroup>
          </div>
        </div> */}
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#e8e6ff",
              }}
            >
              <TableCell align="center"></TableCell>
              <TableCell align="center">
                {selectedLayer !== "Single" ? "Outer Pane" : "Glass Properties"}
              </TableCell>
              {selectedLayer === "Triple" && (
                <TableCell align="center">Middle Pane</TableCell>
              )}
              {(selectedLayer === "Double" || selectedLayer === "Triple") && (
                <TableCell align="center">Inner Pane</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell align="center">Thickness (mm)</TableCell>
              <TableCell align="center">
                {glassValueData.outerPane.isLaminated ? (
                  <input
                    value={glassValueData.outerPane.totalThickness}
                    disabled
                    style={{
                      maxWidth: "100px",
                      textAlign: "center",
                    }}
                  />
                ) : (
                  <select
                    id="outer-pane"
                    value={glassValueData.outerPane.totalThickness || 0}
                    onChange={(e) => {
                      setGlassValueData((prev) => ({
                        ...prev,
                        outerPane: {
                          ...prev.outerPane,
                          totalThickness: Number(e.target.value),
                          glass1Thickness: Number(e.target.value),
                          glass2Thickness: Number(e.target.value),
                        },
                      }));
                    }}
                  >
                    <option value={0}>0</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={8}>8</option>
                    <option value={10}>10</option>
                    <option value={12}>12</option>
                    <option value={15}>15</option>
                  </select>
                )}
              </TableCell>
              {selectedLayer === "Triple" && (
                <TableCell align="center">
                  {glassValueData.middlePane.isLaminated ? (
                    <input
                      value={glassValueData.middlePane.totalThickness}
                      disabled
                      style={{
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    />
                  ) : (
                    <select
                      id="middle-pane"
                      value={glassValueData.middlePane.totalThickness || 0}
                      onChange={(e) => {
                        setGlassValueData((prev) => ({
                          ...prev,
                          middlePane: {
                            ...prev.middlePane,
                            totalThickness: Number(e.target.value),
                            glass1Thickness: Number(e.target.value),
                            glass2Thickness: Number(e.target.value),
                          },
                        }));

                        setSecondTopValue(secondTopValue + 20);
                        setSeoncdLeftValue(secondLeftValue + 20);
                      }}
                    >
                      <option value={0}>0</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={8}>8</option>
                      <option value={10}>10</option>
                      <option value={12}>12</option>
                      <option value={15}>15</option>
                    </select>
                  )}
                </TableCell>
              )}
              {(selectedLayer === "Double" || selectedLayer === "Triple") && (
                <TableCell align="center">
                  {glassValueData.innerPane.isLaminated ? (
                    <input
                      value={glassValueData.innerPane.totalThickness}
                      disabled
                      style={{
                        maxWidth: "100px",
                        textAlign: "center",
                      }}
                    />
                  ) : (
                    <select
                      id="inner-pane"
                      value={glassValueData.innerPane.totalThickness || 0}
                      onChange={(e) => {
                        setGlassValueData((prev) => ({
                          ...prev,
                          innerPane: {
                            ...prev.innerPane,
                            totalThickness: Number(e.target.value),
                            glass1Thickness: Number(e.target.value),
                            glass2Thickness: Number(e.target.value),
                          },
                        }));
                        setFirstTopValue(firstTopValue + 10);
                        setFirstLeftValue(firstLeftValue + 10);
                      }}
                    >
                      <option value={0}>0</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={8}>8</option>
                      <option value={10}>10</option>
                      <option value={12}>12</option>
                      <option value={15}>15</option>
                    </select>
                  )}
                </TableCell>
              )}
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell align="center">Laminated Glass?</TableCell>
              <TableCell align="center">
                <Checkbox
                  id="outer-laminated"
                  value={glassValueData.outerPane.isLaminated || false}
                  onChange={(e) => {
                    setOuterLaminated(e.target.checked);
                    setGlassValueData((prev) => ({
                      ...prev,
                      outerPane: {
                        ...prev.outerPane,
                        isLaminated: e.target.checked,
                      },
                    }));
                    handleTotalThicknessCalculation("outer", e.target.checked);
                  }}
                  sx={{
                    color: "#393184",
                    "&.Mui-checked": {
                      color: "#393184",
                    },
                  }}
                />
              </TableCell>
              {selectedLayer === "Triple" && (
                <TableCell align="center">
                  <Checkbox
                    id="middle-laminated"
                    value={glassValueData.middlePane.isLaminated || false}
                    onChange={(e) => {
                      setMiddleLaminated(e.target.checked);
                      setGlassValueData((prev) => ({
                        ...prev,
                        middlePane: {
                          ...prev.middlePane,
                          isLaminated: e.target.checked,
                        },
                      }));
                      handleTotalThicknessCalculation(
                        "middle",
                        e.target.checked
                      );
                    }}
                    sx={{
                      color: "#393184",
                      "&.Mui-checked": {
                        color: "#393184",
                      },
                    }}
                  />
                </TableCell>
              )}
              {(selectedLayer === "Double" || selectedLayer === "Triple") && (
                <TableCell align="center">
                  <Checkbox
                    id="inner-laminated"
                    value={glassValueData.innerPane.isLaminated || false}
                    onChange={(e) => {
                      setInnerLaminated(e.target.checked);
                      setGlassValueData((prev) => ({
                        ...prev,
                        innerPane: {
                          ...prev.innerPane,
                          isLaminated: e.target.checked,
                        },
                      }));
                      handleTotalThicknessCalculation(
                        "inner",
                        e.target.checked
                      );
                    }}
                    sx={{
                      color: "#393184",
                      "&.Mui-checked": {
                        color: "#393184",
                      },
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
            {(outerLaminated || middleLaminated || innerLaminated) && (
              <>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="center">Glass 1 Thickness (mm)</TableCell>
                  <TableCell align="center">
                    {outerLaminated && (
                      <select
                        value={glassValueData.outerPane.glass1Thickness || 0}
                        onChange={(e) => {
                          setGlassValueData((prev) => ({
                            ...prev,
                            outerPane: {
                              ...prev.outerPane,
                              glass1Thickness: Number(e.target.value),
                              totalThickness:
                                Number(prev.outerPane.glass2Thickness) +
                                Number(e.target.value) +
                                Number(prev.outerPane.interlayerThickness),
                            },
                          }));
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={8}>8</option>
                        <option value={10}>10</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    )}
                  </TableCell>
                  {selectedLayer === "Triple" && (
                    <TableCell align="center">
                      {middleLaminated && (
                        <select
                          value={glassValueData.middlePane.glass1Thickness || 0}
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              middlePane: {
                                ...prev.middlePane,
                                glass1Thickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.middlePane.totalThickness) +
                                  Number(e.target.value) +
                                  Number(prev.middlePane.interlayerThickness),
                              },
                            }));
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={8}>8</option>
                          <option value={10}>10</option>
                          <option value={12}>12</option>
                          <option value={15}>15</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                  {(selectedLayer === "Double" ||
                    selectedLayer === "Triple") && (
                    <TableCell align="center">
                      {innerLaminated && (
                        <select
                          value={glassValueData.innerPane.glass1Thickness || 0}
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              innerPane: {
                                ...prev.innerPane,
                                glass1Thickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.innerPane.totalThickness) +
                                  Number(e.target.value) +
                                  Number(prev.innerPane.interlayerThickness),
                              },
                            }));
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={8}>8</option>
                          <option value={10}>10</option>
                          <option value={12}>12</option>
                          <option value={15}>15</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="center">
                    Interlayer Thickness (mm)
                  </TableCell>
                  <TableCell align="center">
                    {outerLaminated && (
                      <select
                        value={
                          glassValueData.outerPane.interlayerThickness || 1.52
                        }
                        onChange={(e) => {
                          setGlassValueData((prev) => ({
                            ...prev,
                            outerPane: {
                              ...prev.outerPane,
                              interlayerThickness: Number(e.target.value),
                              totalThickness:
                                Number(prev.outerPane.glass1Thickness) +
                                Number(e.target.value) +
                                Number(prev.outerPane.glass2Thickness),
                            },
                          }));
                        }}
                      >
                        <option value={0.76}>0.76</option>
                        <option value={1.52} selected>
                          1.52
                        </option>
                        <option value={2.28}>2.28</option>
                        <option value={3.04}>3.04</option>
                      </select>
                    )}
                  </TableCell>
                  {selectedLayer === "Triple" && (
                    <TableCell align="center">
                      {middleLaminated && (
                        <select
                          value={
                            glassValueData.middlePane.interlayerThickness ||
                            1.52
                          }
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              middlePane: {
                                ...prev.middlePane,
                                interlayerThickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.middlePane.glass1Thickness) +
                                  Number(e.target.value) +
                                  Number(prev.middlePane.glass2Thickness),
                              },
                            }));
                          }}
                        >
                          <option value={0.76}>0.76</option>
                          <option value={1.52} selected>
                            1.52
                          </option>
                          <option value={2.28}>2.28</option>
                          <option value={3.04}>3.04</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                  {(selectedLayer === "Double" ||
                    selectedLayer === "Triple") && (
                    <TableCell align="center">
                      {innerLaminated && (
                        <select
                          value={
                            glassValueData.innerPane.interlayerThickness || 1.52
                          }
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              innerPane: {
                                ...prev.innerPane,
                                interlayerThickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.innerPane.glass1Thickness) +
                                  Number(e.target.value) +
                                  Number(prev.innerPane.glass2Thickness),
                              },
                            }));
                          }}
                        >
                          <option value={0.76}>0.76</option>
                          <option value={1.52} selected>
                            1.52
                          </option>
                          <option value={2.28}>2.28</option>
                          <option value={3.04}>3.04</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                </TableRow>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell align="center">Glass 2 Thickness (mm)</TableCell>
                  <TableCell align="center">
                    {outerLaminated && (
                      <select
                        value={glassValueData.outerPane.glass2Thickness || 0}
                        onChange={(e) => {
                          setGlassValueData((prev) => ({
                            ...prev,
                            outerPane: {
                              ...prev.outerPane,
                              glass2Thickness: Number(e.target.value),
                              totalThickness:
                                Number(prev.outerPane.glass1Thickness) +
                                Number(e.target.value) +
                                Number(prev.outerPane.interlayerThickness),
                            },
                          }));
                        }}
                      >
                        <option value={0}>0</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={8}>8</option>
                        <option value={10}>10</option>
                        <option value={12}>12</option>
                        <option value={15}>15</option>
                      </select>
                    )}
                  </TableCell>
                  {selectedLayer === "Triple" && (
                    <TableCell align="center">
                      {middleLaminated && (
                        <select
                          value={glassValueData.middlePane.glass2Thickness || 0}
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              middlePane: {
                                ...prev.middlePane,
                                glass2Thickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.middlePane.glass1Thickness) +
                                  Number(e.target.value) +
                                  Number(prev.middlePane.interlayerThickness),
                              },
                            }));
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={8}>8</option>
                          <option value={10}>10</option>
                          <option value={12}>12</option>
                          <option value={15}>15</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                  {(selectedLayer === "Double" ||
                    selectedLayer === "Triple") && (
                    <TableCell align="center">
                      {innerLaminated && (
                        <select
                          value={glassValueData.innerPane.glass2Thickness || 0}
                          onChange={(e) => {
                            setGlassValueData((prev) => ({
                              ...prev,
                              innerPane: {
                                ...prev.innerPane,
                                glass2Thickness: Number(e.target.value),
                                totalThickness:
                                  Number(prev.innerPane.glass1Thickness) +
                                  Number(e.target.value) +
                                  Number(prev.innerPane.interlayerThickness),
                              },
                            }));
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={8}>8</option>
                          <option value={10}>10</option>
                          <option value={12}>12</option>
                          <option value={15}>15</option>
                        </select>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedLayer !== "Single" && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#e8e6ff",
                }}
              >
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {selectedLayer === "Double" ? "Gap Properties" : "Gap 1"}
                </TableCell>
                {selectedLayer === "Triple" && (
                  <TableCell align="center">Gap 2</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">Gas Type</TableCell>
                <TableCell align="center">
                  <span className="spacer-value">Argon</span>
                </TableCell>
                {selectedLayer === "Triple" && (
                  <TableCell align="center">
                    <span className="spacer-value">Argon</span>
                  </TableCell>
                )}
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">Spacer Thickness (mm)</TableCell>
                <TableCell align="center">
                  <select>
                    <option value={6}>6</option>
                    <option value={12}>12</option>
                    <option value={15}>15</option>
                  </select>
                </TableCell>
                {selectedLayer === "Triple" && (
                  <TableCell align="center">
                    <select>
                      <option value={6}>6</option>
                      <option value={12}>12</option>
                      <option value={15}>15</option>
                    </select>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className="step-grid">
        <div className="step-input-alignment">
          <FormLabel>Select Number of Glasses</FormLabel>

          <div className="input-details">
            <select value={inputCount} onChange={handleInputCountChange}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
        </div>
        <div className="step-grid">
          <div className="step-input-alignment">
            <FormLabel>Total Width</FormLabel>
            <div className="input-details">
              <input
                style={{
                  textAlign: "center",
                }}
                value={totalWidth}
                disabled
              />
            </div>
          </div>
          <div className="step-input-alignment">
            <FormLabel>Total Height</FormLabel>
            <div className="input-details">
              <input
                style={{
                  textAlign: "center",
                }}
                value={totalHeight}
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="step-input-alignment">
        {dimensions.map((dim, index) => (
          <div className="step-grid">
            <div className="input-details" key={index}>
              <input
                type="number"
                placeholder="Width"
                value={dim.width}
                onChange={(e) => {
                  console.log("first", e.target.value);
                  handleDimensionChange(index, "width", e.target.value);
                }}
              />
            </div>
            <div className="input-details" key={index}>
              <input
                type="number"
                placeholder="Height"
                value={dim.height}
                onChange={(e) => {
                  console.log("second", e.target.value);
                  handleDimensionChange(index, "height", e.target.value);
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="step-input-alignment">
        <div className="calculate-button-alignment">
          <button
            className="calculate-button"
            onClick={() => {
              setSavingsInDGU1(0);
              setSavingsInDGU2(0);
              setAveragePerMonthSavingSGU(0);
              setAveragePerMonthSaving(0);
              calculateSavingsInDGU();
            }}
          >
            Calculate Glass Value
          </button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableBody>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell>
                Savings in DGU Compared to Single 3mm Clear Toughened Glass in 1
                Year (As per ASHRAE)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "100px",
                }}
              >
                <span className="spacer-value">{savingsInDGU1}</span>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell>Average Per Month Savings</TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "100px",
                }}
              >
                <span className="spacer-value">{averagePerMonthSaving}</span>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell>
                Savings in DGU Compared to Single Glass Unit of Same Glass in 1
                Year
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "100px",
                }}
              >
                <span className="spacer-value">{savingsInDGU2}</span>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell>Average Per Month Savings SGU : DGU</TableCell>
              <TableCell
                align="center"
                sx={{
                  width: "100px",
                }}
              >
                <span className="spacer-value">{averagePerMonthSavingSGU}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default NorthFacade;
