import "./ContactUs.scss";
import React, { useRef, useState } from "react";
import ContactUsBanner from "../../assets/images/contact-us-banner.png";
import PhoneIcon from "../../assets/icons/phone-icon.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import LocationIcon from "../../assets/icons/location-icon.svg";
import EmailIcon from "../../assets/icons/email-icon.svg";
import CalendarIcon from "../../assets/icons/calendar-glass-icon.svg";
import QuoteIcon from "../../assets/icons/quote-glass-icon.svg";
import SquareIcon from "../../assets/icons/square-glass-icon.svg";
import QuestionIcon from "../../assets/icons/question-glass-icon.svg";
import UserIcon from "../../assets/icons/user-icon-2.svg";
import PhoneIcon2 from "../../assets/icons/phone-icon2.svg";
import WPIcon from "../../assets/icons/wp-icon2.svg";
import LocationIcon2 from "../../assets/icons/location-icon3.svg";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

function ContactUs() {
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const [showTeam, setShowTeam] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const contactTeamData = [
    {
      name: "Chetan Singh",
      designation: "Plant Head",
      mobileNo: "7572857272",
    },
    {
      name: "Adwin Christian",
      designation: "Customer Relation Manager",
      mobileNo: "9375725562",
    },
    {
      name: "Ranjit Guchhait",
      designation: "Accounts Head",
      mobileNo: "7572837272",
    },
    {
      name: "Mit Mehta",
      designation: "Logistics Head",
      mobileNo: "9375725561",
    },
    {
      name: "Sandeep Shekhar",
      designation: "Plant Manager",
      mobileNo: "9375725565",
    },
    {
      name: "Pankaj Shrivastava",
      designation: "Gujarat Marketing",
      mobileNo: "9377795752",
    },
    {
      name: "Manish Shah",
      designation: "Mumbai Marketing",
      mobileNo: "9324161302",
    },
  ];

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="main-contact-us-wrapper">
      <div className="contact-us-content-wrapper">
        <div className="contact-us-banner">
          <div className="banner-content-wrapper">
            <img className="banner-image" src={ContactUsBanner} alt="" />
            <div className="banner-text-wrapper">
              <div className="banner-header-text">
                <h2 className="welcome-text">
                  Let's Build Something Brilliant Together
                </h2>
                <p>
                  Looking to elevate your space with innovative and sustainable
                  glass solutions?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="contact-us-description-wrapper">
            <span>
              Turning your vision into reality, we offer high-quality glass
              products and services, from custom tempering and lamination to
              energy-efficient IGUs.
            </span>
            <p>
              Our team of experts is dedicated to providing you with exceptional
              customer service and expert advice.
            </p>
            <p
              style={{
                marginBottom: "0",
              }}
            >
              Get in touch today to discuss your project
            </p>
          </div>
        </div>

        <div className="container">
          <div className="contact-us-glass-icons-wrapper">
            <div className="glass-icons-grid-wrapper">
              <div className="glass-icons-grid-item">
                <div className="our-milestone-icon" onClick={scrollToTarget}>
                  <img src={CalendarIcon} alt="Schedule a consultation" />
                </div>
                <div className="icon-text" onClick={scrollToTarget}>
                  Schedule a consultation
                </div>
              </div>
              <div className="glass-icons-grid-item">
                <div className="our-milestone-icon" onClick={scrollToTarget}>
                  <img src={QuoteIcon} alt="Request a quote" />
                </div>
                <div className="icon-text" onClick={scrollToTarget}>
                  Request a quote
                </div>
              </div>
              <div className="glass-icons-grid-item">
                <div
                  className="our-milestone-icon"
                  onClick={() => navigate("/products")}
                >
                  <img src={SquareIcon} alt="Explore our products" />
                </div>
                <div
                  className="icon-text"
                  onClick={() => navigate("/products")}
                >
                  Explore our products
                </div>
              </div>
              <div className="glass-icons-grid-item">
                <div className="our-milestone-icon" onClick={scrollToTarget}>
                  <img src={QuestionIcon} alt="Have a question?" />
                </div>
                <div className="icon-text" onClick={scrollToTarget}>
                  Have a question?
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="connect-with-us-wrapper">
          <div className="container">
            <div ref={targetRef} className="contact-section-title">
              Connect with us
            </div>
            <div className="connect-with-us-description">
              <p>
                In addition to the contact form below, you can also reach us
                through the following channels
              </p>
            </div>
            <div className="connect-with-us-card">
              <div className="contact-information-wrapper">
                <div className="contact-information-header">
                  Contact Information
                </div>
                <div className="information-alignment">
                  <div className="information-wrapper">
                    <div className="contact-phone">
                      <div className="phone-icon">
                        <img src={PhoneIcon} alt="" />
                      </div>
                      <div className="phone-information">
                        <div className="phone-title">Phone</div>
                        <div className="phone-value">+91 75728 77272</div>
                      </div>
                    </div>
                    <hr className="line-alignment" />
                  </div>
                  <div className="information-wrapper">
                    <div className="contact-phone">
                      <div className="phone-icon">
                        <img src={EmailIcon} alt="" />
                      </div>
                      <div className="phone-information">
                        <div className="phone-title">Email</div>
                        <div className="phone-value">sales@jgs.co.in</div>
                      </div>
                    </div>
                    <hr className="line-alignment" />
                  </div>
                  <div className="information-wrapper">
                    <div className="contact-phone">
                      <div className="phone-icon">
                        <img src={LocationIcon} alt="" />
                      </div>
                      <div className="phone-information">
                        <div className="phone-title">Address</div>
                        <div className="phone-value">
                          <p>AA-15-C SUSML, Hojiwala Industrial</p>
                          <p>Estates, Gate No. 2, Sachin Palsana</p>
                          <p>Highway, Sachin, Surat, Gujarat 394230</p>
                        </div>
                      </div>
                    </div>
                    <div className="contact-phone">
                      <div className="phone-icon">
                        <img src={LocationIcon} alt="" />
                      </div>
                      <div className="phone-information">
                        <div
                          className="phone-value"
                          style={{
                            marginTop: "15px",
                          }}
                        >
                          <p>Plot No B-12, 3 & 4, Hojiwala Industrial</p>
                          <p>Estate Road No. 12, Surat - Palsana,</p>
                          <p>Sachin, Surat, Gujarat 394230</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="follow-us-wrapper">
                  <div className="follow-us-title">Follow us on</div>
                  <div className="follow-us-icons-wrapper">
                    <InstagramIcon
                      sx={{
                        background: "white",
                        borderRadius: "50%",
                        padding: "10px",
                        color: "#393184",
                      }}
                    />
                    <FacebookIcon
                      sx={{
                        background: "white",
                        borderRadius: "50%",
                        padding: "10px",
                        color: "#393184",
                      }}
                    />
                    <TwitterIcon
                      sx={{
                        background: "white",
                        borderRadius: "50%",
                        padding: "10px",
                        color: "#393184",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-form-wrapper">
                <div className="field-alignment-wrapper">
                  <TextField
                    id="firstName"
                    label="First Name"
                    variant="standard"
                  />
                  <TextField
                    id="lastName"
                    label="Last Name"
                    variant="standard"
                  />
                  <TextField id="email" label="Email" variant="standard" />
                  <TextField id="phone" label="Phone" variant="standard" />
                </div>
                <div className="full-field-alignment-wrapper">
                  <TextField
                    id="projectDescription"
                    sx={{
                      width: "100%",
                    }}
                    label="Project Description"
                    variant="standard"
                  />
                </div>
                <div className="full-field-alignment-wrapper">
                  <TextField
                    id="filled-multiline-static"
                    label="Message"
                    multiline
                    rows={5}
                    variant="filled"
                    sx={{
                      width: "100%",
                    }}
                  />
                </div>

                <button>Submit</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="location-details-alignment">
            <div className="location-details-grid ">
              <div className="location-details-grid-item">
                <div className="location-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29781.78221273311!2d72.86690888821134!3d21.08373175003372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be05a0f5f19d839%3A0x812b96d0d7edf723!2sJain%20Glass%20Solutions!5e0!3m2!1sen!2sin!4v1716458291160!5m2!1sen!2sin"
                    style={{
                      border: 0,
                    }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Jain Glass Solutions"
                  ></iframe>
                </div>
                <div className="location-details-alignment">
                  <div className="location-left">
                    <div>
                      <img src={LocationIcon2} alt="LocationIcon" />
                    </div>
                    <div>
                      <h6>Jain Glass Solutions</h6>
                      <p>
                        AA-15-C SUSML, Hojiwala Industrial Estates, Road No. 10
                        Gate No. 2, Sachin Palsana Highway, Sachin, Surat,
                        Gujarat 394230
                      </p>
                    </div>
                  </div>
                  <div className="location-right">
                    <img src={PhoneIcon2} alt="PhoneIcon2" />
                  </div>
                </div>
              </div>
              <div className="location-details-grid-item">
                <div className="location-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29781.78221273311!2d72.86690888821134!3d21.08373175003372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be051cc5a931cfb%3A0x7dd03a40a2726236!2sJGS%20Glass%20Industries!5e0!3m2!1sen!2sin!4v1716458756345!5m2!1sen!2sin"
                    style={{
                      border: 0,
                    }}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="JGS Glass Industries"
                  ></iframe>
                </div>

                <div className="location-details-alignment">
                  <div className="location-left">
                    <div>
                      <img src={LocationIcon2} alt="LocationIcon" />
                    </div>
                    <div>
                      <h6>JGS Glass Industries</h6>
                      <p>
                        Plot No B-12, 3 & 4, Hojiwala Industrial Estate Road No.
                        12, Surat - Palsana, Sachin, Surat, Gujarat 394230
                      </p>
                    </div>
                  </div>
                  <div className="location-right">
                    <img src={PhoneIcon2} alt="PhoneIcon2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
