import React, { useEffect, useState } from "react";
import "./OurClient.scss";
import UserIcon from "../../../assets/icons/user-white-icon.svg";
import MapLogo1 from "../../../assets/images/1.png";
import MapLogo2 from "../../../assets/images/2.png";
import MapLogo3 from "../../../assets/images/3.png";
import MapLogo4 from "../../../assets/images/4.png";
import MapLogo5 from "../../../assets/images/5.png";
import MapLogo6 from "../../../assets/images/6.png";
import MapLogo7 from "../../../assets/images/7.png";
import MapLogo8 from "../../../assets/images/8.png";
import MapLogo9 from "../../../assets/images/9.png";
import MapLogo10 from "../../../assets/images/10.png";
import MapLogo11 from "../../../assets/images/11.png";
import MapLogo12 from "../../../assets/images/12.png";
import MapLogo13 from "../../../assets/images/13.png";
import MapLogo14 from "../../../assets/images/14.png";
import MapLogo15 from "../../../assets/images/15.png";
import MapLogo16 from "../../../assets/images/16.png";
import MapLogo17 from "../../../assets/images/17.png";
import MapLogo18 from "../../../assets/images/18.png";
import MapLogo19 from "../../../assets/images/19.png";
import MapLogo20 from "../../../assets/images/20.png";
import { useMediaQuery } from "@mui/material";
import Slider from "react-slick";

const clientIndex = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
];

export default function OurClient() {
  const [currentIndex, setCurrentIndex] = useState(19);
  const isMobile = useMediaQuery("(max-width:1024px)");

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1500,
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const imageUrls = [
    MapLogo1,
    MapLogo2,
    MapLogo3,
    MapLogo4,
    MapLogo5,
    MapLogo6,
    MapLogo7,
    MapLogo8,
    MapLogo9,
    MapLogo10,
    MapLogo11,
    MapLogo12,
    MapLogo13,
    MapLogo14,
    MapLogo15,
    MapLogo16,
    MapLogo17,
    MapLogo18,
    MapLogo19,
    MapLogo20,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % clientIndex.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="our-clients-section">
      <div className="container">
        {!isMobile && (
          <div className="our-clients-wrapper">
            <h2 className="home-section-title">Our Clients</h2>
            <div className="our-client-details-alignment">
              <div className="india-map-alignment">
                <div
                  className={`main-round-box ${
                    currentIndex === 0 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo1}
                        alt="MapLogo1"
                        style={{
                          height: "98px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 1 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo2}
                        alt="MapLogo2"
                        style={{
                          height: "98px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 2 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo3}
                        alt="MapLogo3"
                        style={{
                          height: "145px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 3 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo4}
                        alt="MapLogo4"
                        style={{
                          height: "106px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 4 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo5}
                        alt="MapLogo5"
                        style={{
                          height: "106px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 5 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo6}
                        alt="MapLogo6"
                        style={{
                          height: "100px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 6 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo7}
                        alt="MapLogo7"
                        style={{
                          height: "88px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 7 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo8}
                        alt="MapLogo8"
                        style={{
                          height: "66px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 8 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo9}
                        alt="MapLogo9"
                        style={{
                          height: "92px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 9 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo10}
                        alt="MapLogo10"
                        style={{
                          height: "85px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 10 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo11}
                        alt="MapLogo11"
                        style={{
                          height: "85px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 11 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo12}
                        alt="MapLogo12"
                        style={{
                          height: "74px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 12 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo13}
                        alt="MapLogo13"
                        style={{
                          height: "88px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 13 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo14}
                        alt="MapLogo14"
                        style={{
                          height: "64px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 14 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo15}
                        alt="MapLogo15"
                        style={{
                          height: "88px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 15 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo16}
                        alt="MapLogo16"
                        style={{
                          height: "88px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 16 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo17}
                        alt="MapLogo17"
                        style={{
                          height: "84px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`main-round-box ${
                    currentIndex === 17 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo18}
                        alt="MapLogo18"
                        style={{
                          height: "96px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 18 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo19}
                        alt="MapLogo19"
                        style={{
                          height: "36px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`main-round-box ${
                    currentIndex === 19 ? "viewBox" : ""
                  }`}
                >
                  <div className="child-round-box">
                    <img src={UserIcon} alt="UserIcon" />
                  </div>
                  <div className="parent">
                    <div className="hover-details">
                      <img
                        src={MapLogo20}
                        alt="MapLogo20"
                        style={{
                          height: "88px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="mobile-our-clients-wrapper">
            <h2 className="home-section-title">Our Clients</h2>
            <div className="mobile-our-clients-alignment">
              <Slider {...settings}>
                {imageUrls?.map((url, index) => {
                  return (
                    <div className="client-logo-wrapper" key={index}>
                      <img src={url} alt="Client" className="logo-image" />
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
