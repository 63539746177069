import React from "react";
import "./GlassConfiguration.scss";
import Calc1 from "../../../assets/images/calc-1.png";
import Calc2 from "../../../assets/images/calc-2.png";
import Calc3 from "../../../assets/images/calc-3.png";
import Calc4 from "../../../assets/images/calc-4.png";
import AnimatedSection from "../../../components/Animation/AnimatedSection";

export default function GlassConfiguration() {
  return (
    <div className="glass-configuration-section">
      <div className="container">
        <div className="glass-configuration-alignment">
          <h2>
            Are you stuck on glass choices for your next project? We've got you!
            Our online calculator makes it easy. With just a few clicks, you'll
            have all the necessary info.
          </h2>
        </div>

        <div className="glass-configuration-all-details-alignment">
          <div className="glass-configuration-grid">
            <div className="glass-configuration-left-side">
              <div className="glass-configuration-box">
                <AnimatedSection animationType="fade" duration="900">
                  <img src={Calc1} alt="" />
                </AnimatedSection>
              </div>
              <div className="glass-configuration-box">
                <AnimatedSection animationType="fade" duration="900">
                  <img src={Calc2} alt="" />
                </AnimatedSection>
              </div>
              <div className="glass-configuration-box">
                <AnimatedSection animationType="fade" duration="900">
                  <img src={Calc3} alt="" />
                </AnimatedSection>
              </div>
              <div className="glass-configuration-box">
                <AnimatedSection animationType="fade" duration="900">
                  <img src={Calc3} alt="" />
                </AnimatedSection>
              </div>
              <div className="glass-configuration-box">
                <AnimatedSection animationType="fade" duration="900">
                  <img src={Calc4} alt="" />
                </AnimatedSection>
              </div>
            </div>

            <div className="glass-configuration-right-side">
              <div className="right-grid">
                <div className="main-round-box">
                  <div className="round-box">
                    <span>1</span>
                  </div>
                </div>

                <div className="main-round-text">
                  <p>Select Glass Configuration</p>
                </div>
              </div>
              <div className="right-grid">
                <div className="main-round-box">
                  <div className="round-box">
                    <span>2</span>
                  </div>
                </div>

                <div className="main-round-text">
                  <p>
                    Choose your desired glass type and configuration from the
                    dropdown menus.
                  </p>
                </div>
              </div>
              <div className="right-grid">
                <div className="main-round-box">
                  <div className="round-box">
                    <span>3</span>
                  </div>
                </div>

                <div className="main-round-text">
                  <p>Specify Surface Emissivity</p>
                </div>
              </div>
              <div className="right-grid">
                <div className="main-round-box">
                  <div className="round-box">
                    <span>4</span>
                  </div>
                </div>

                <div className="main-round-text">
                  <p>
                    Input the surface emissivity value based on your glass
                    specifications.
                  </p>
                </div>
              </div>
              <div className="right-grid">
                <div className="main-round-box">
                  <div className="round-box">
                    <span>5</span>
                  </div>
                </div>

                <div className="main-round-text">
                  <p>
                    Hit the calculate button and instantly receive accurate
                    U-value, SHGC, and shading coefficient data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
