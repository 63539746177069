import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AboutUs from "./pages/AboutUs/AboutUs";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import Products from "./pages/Products/Products";
import Projects from "./pages/Projects/Projects";
import Calculator from "./pages/Calculator/Calculator";
import Infrastructure from "./pages/Infrastructure/Infrastructure";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <Router>
      <div className="main-layout-wrapper">
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/products" element={<Products />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/smart-glass-calculator" element={<Calculator />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
