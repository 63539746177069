import "./Infrastructure.scss";
import React from "react";
import Infra1 from "../../assets/images/infra-1.png";
import Infra2 from "../../assets/images/infra-2.png";
import Infra3 from "../../assets/images/infra-3.png";
import Infra4 from "../../assets/images/infra-4.png";
import Infra5 from "../../assets/images/infra-5.png";
import Infra6 from "../../assets/images/infra-6.png";
import Infra7 from "../../assets/images/infra-7.png";
import Infra8 from "../../assets/images/infra-8.png";
import Infra9 from "../../assets/images/infra-9.png";

function Infrastructure() {
  const infrastructureDetails = [
    {
      id: "infra1",
      title: "Biesse Intermac Cutting Line",
      image: Infra1,
      details: [
        "Cutting machine is fine Italic build machine from Intermac.",
        "Automatic cutter cuts sheets from 4mm to 19mm thickness.",
        "Maximum cutting size: 3300mm x 6100mm",
      ],
    },
    {
      id: "infra2",
      title: "Hantech Edge Seaming Line",
      image: Infra2,
      details: [
        "High-speed Seaming with washing machine.",
        "Designed specially for low-e glass",
        "Maximum cutting size: 1800mm x 2400mm",
      ],
    },
    {
      id: "infra3",
      title: "Hiseng Four Edger Line",
      image: Infra3,
      details: [
        "Two fully automatic double edger machine for crystal edging of glass from both opposite sides of Shunde Hiseng company from China.",
        "Maximum edging size - 3000mm x 5000mm",
        "Thickness ranges from 4mm to 19mm.",
      ],
    },
    {
      id: "infra4",
      title: "Cool Temper Jetstream RT Furnace",
      image: Infra4,
      details: [
        "Fully automatic Jetstream RT furnace is of Cool Temper (TCME), UK",
        "Accepted thickness range from 4mm ~19mm.",
        "Maximum accepted size of 2440mm x 4880mm.",
      ],
    },
    {
      id: "infra5",
      title: "North Glass Tempering Line",
      image: Infra5,
      details: [
        "Fully automatic double blower horizontal furnace is of North Glass.",
        "Accepted thickness range from 4mm to 19mm.",
        "Maximum accepted size of 2440mm x 3660mm.",
      ],
    },
    {
      id: "infra6",
      title: "Hanjiang Automatic Insulating Glass Line",
      image: Infra6,
      details: [
        "Automatic argon gas filling line with sealing robot",
        "Max size: 2440mm x 4270mm",
        "Sealing robot - with 2 servo pumps",
      ],
    },
    {
      id: "infra7",
      title: "Handong Automatic Lamination Line",
      image: Infra7,
      details: [
        "Maximum size: 2440mm x 4880mm",
        "Forced convection lamination line with autoclave",
        "High positioning accuracy for multi layers of glasses",
      ],
    },
    {
      id: "infra8",
      title: "Handong Autoclave",
      image: Infra8,
      details: [
        "Autoclave with infrared Heaters",
        "The circulating fans homogeneously heat the glasses via air convection in circular motion.",
        "Maximum size: 2440mm x 4880mm",
      ],
    },
    {
      id: "infra9",
      title: "Heat Soak",
      image: Infra9,
      details: [
        "Max Size: 2440mm x 4880mm",
        "Minimizing the risk of spontaneous breakage.",
        "Ensure safety & reliability of glasses",
      ],
    },
  ];

  return (
    <div className="main-infrastructure-wrapper">
      <div className="infrastructure-content-wrapper">
        <div className="infrastructure-banner">
          <div className="banner-content-wrapper">
            <video autoPlay loop muted id="infravideo" className="banner-image">
              <source
                src="https://s3-content-purpose.s3.ap-south-1.amazonaws.com/website+teaser+(1).mp4"
                type="video/mp4"
              />
            </video>
            <div className="banner-text-wrapper">
              <div className="banner-header-text">
                <h2 className="welcome-text fadeinout">
                  Shaping glass industries with cutting edge technology
                </h2>
                <p>
                  Looking to elevate your space with innovative and sustainable
                  glass solutions?
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div class="wrapper">
            {infrastructureDetails.map((item) => (
              <div key={item.id} class="card">
                <img src={item.image} alt={item.title} />
                <div class="info">
                  <h1>{item.title}</h1>
                  <ul className="item-content-list">
                    {item.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infrastructure;
