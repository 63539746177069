export const neutralAppearance = [
  {
    _id: "664f534027f985ae72baf8c6",
    colourSLCode: "20",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Solar Plus",
    code: "Neutral 27",
    lightTransmissionPerc: "27",
    externalReflectionPerc: "14",
    internalReflectionPerc: "9",
    shgc_sf: "0.28",
    shadingCoefficient: "0.33",
    u_value: "2.98",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8cc",
    colourSLCode: "26",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Solar Plus",
    code: "Neutral 45",
    lightTransmissionPerc: "44",
    externalReflectionPerc: "16",
    internalReflectionPerc: "6",
    shgc_sf: "0.35",
    shadingCoefficient: "0.41",
    u_value: "3.23",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "Clear 4-12 mm",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8de",
    colourSLCode: "44",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Iris",
    brands: "Envision",
    code: "SKN 144",
    lightTransmissionPerc: "41",
    externalReflectionPerc: "20",
    internalReflectionPerc: "15",
    shgc_sf: "0.24",
    shadingCoefficient: "0.27",
    u_value: "1.60",
    lightTransmissionDir: "w,n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e7",
    colourSLCode: "53",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Quasar",
    brands: "Envision",
    code: "SKN 754",
    lightTransmissionPerc: "33",
    externalReflectionPerc: "10",
    internalReflectionPerc: "21",
    shgc_sf: "0.21",
    shadingCoefficient: "0.24",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8b9",
    colourSLCode: "7",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Double Silver",
    code: "Neutral 30",
    lightTransmissionPerc: "29",
    externalReflectionPerc: "18",
    internalReflectionPerc: "15",
    shgc_sf: "0.19",
    shadingCoefficient: "0.22",
    u_value: "1.67",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8ce",
    colourSLCode: "28",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Platinum",
    brands: "Cool-Lite",
    code: "ST 108",
    lightTransmissionPerc: "8",
    externalReflectionPerc: "43",
    internalReflectionPerc: "36",
    shgc_sf: "0.10",
    shadingCoefficient: "0.11",
    u_value: "1.90",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e1",
    colourSLCode: "47",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "Envision Plus",
    code: "SKN 176",
    lightTransmissionPerc: "69",
    externalReflectionPerc: "13",
    internalReflectionPerc: "15",
    shgc_sf: "0.37",
    shadingCoefficient: "0.43",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e3",
    colourSLCode: "49",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Nebula",
    brands: "Envision",
    code: "SKN 454",
    lightTransmissionPerc: "42",
    externalReflectionPerc: "14",
    internalReflectionPerc: "22",
    shgc_sf: "0.24",
    shadingCoefficient: "0.27",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e6",
    colourSLCode: "52",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Lumosa",
    brands: "Envision",
    code: "SKN 744",
    lightTransmissionPerc: "26",
    externalReflectionPerc: "11",
    internalReflectionPerc: "14",
    shgc_sf: "0.18",
    shadingCoefficient: "0.21",
    u_value: "1.60",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8d1",
    colourSLCode: "31",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Dew Drop",
    brands: "Cool-Lite",
    code: "ST 150",
    lightTransmissionPerc: "46",
    externalReflectionPerc: "20",
    internalReflectionPerc: "22",
    shgc_sf: "0.47",
    shadingCoefficient: "0.54",
    u_value: "2.80",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8bd",
    colourSLCode: "11",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Solar",
    code: "Neutral 34",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "23",
    internalReflectionPerc: "8",
    shgc_sf: "0.41",
    shadingCoefficient: "0.48",
    u_value: "4.30",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8ba",
    colourSLCode: "8",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Double Silver",
    code: "Neutral 40",
    lightTransmissionPerc: "39",
    externalReflectionPerc: "19",
    internalReflectionPerc: "13",
    shgc_sf: "0.23",
    shadingCoefficient: "0.27",
    u_value: "1.65",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8cf",
    colourSLCode: "29",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Sterling Silver",
    brands: "Cool-Lite",
    code: "ST 120",
    lightTransmissionPerc: "18",
    externalReflectionPerc: "32",
    internalReflectionPerc: "30",
    shgc_sf: "0.21",
    shadingCoefficient: "0.25",
    u_value: "2.60",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8df",
    colourSLCode: "45",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Magma",
    brands: "Envision",
    code: "SKN 154",
    lightTransmissionPerc: "51",
    externalReflectionPerc: "18",
    internalReflectionPerc: "22",
    shgc_sf: "0.28",
    shadingCoefficient: "0.33",
    u_value: "1.50",
    lightTransmissionDir: "w,n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8ec",
    colourSLCode: "58",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "N",
    brands: "Equinox",
    code: "Equinox N",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "13",
    internalReflectionPerc: "10",
    shgc_sf: "0.19",
    shadingCoefficient: "0.22",
    u_value: "1.60",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8ed",
    colourSLCode: "59",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear Cosmos",
    brands: "Evo",
    code: "ET 125",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "29",
    internalReflectionPerc: "15",
    shgc_sf: "0.23",
    shadingCoefficient: "0.27",
    u_value: "2.00",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f0",
    colourSLCode: "62",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Evo Plus",
    code: "ET 135",
    lightTransmissionPerc: "31",
    externalReflectionPerc: "24",
    internalReflectionPerc: "14",
    shgc_sf: "0.28",
    shadingCoefficient: "0.32",
    u_value: "2.10",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8c8",
    colourSLCode: "22",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Solar Plus",
    code: "Clear 65",
    lightTransmissionPerc: "67",
    externalReflectionPerc: "10",
    internalReflectionPerc: "4",
    shgc_sf: "0.54",
    shadingCoefficient: "0.63",
    u_value: "3.76",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8e2",
    colourSLCode: "48",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Futura",
    brands: "Envision",
    code: "SKN 444",
    lightTransmissionPerc: "34",
    externalReflectionPerc: "15",
    internalReflectionPerc: "14",
    shgc_sf: "0.20",
    shadingCoefficient: "0.23",
    u_value: "1.60",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e4",
    colourSLCode: "50",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Supernova",
    brands: "Envision Plus",
    code: "SKN 465",
    lightTransmissionPerc: "49",
    externalReflectionPerc: "13",
    internalReflectionPerc: "18",
    shgc_sf: "0.27",
    shadingCoefficient: "0.32",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8b3",
    colourSLCode: "1",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  High Performance",
    code: "Neutral Plus 50",
    lightTransmissionPerc: "49",
    externalReflectionPerc: "26",
    internalReflectionPerc: "21",
    shgc_sf: "0.32",
    shadingCoefficient: "0.36",
    u_value: "1.31",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8be",
    colourSLCode: "12",
    company: "GG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "SunGuard  Solar",
    code: "Neutral 67",
    lightTransmissionPerc: "66",
    externalReflectionPerc: "16",
    internalReflectionPerc: "18",
    shgc_sf: "0.70",
    shadingCoefficient: "0.82",
    u_value: "5.26",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d0",
    colourSLCode: "30",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Graphite",
    brands: "Cool-Lite",
    code: "ST 136",
    lightTransmissionPerc: "34",
    externalReflectionPerc: "23",
    internalReflectionPerc: "23",
    shgc_sf: "0.35",
    shadingCoefficient: "0.40",
    u_value: "2.70",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8db",
    colourSLCode: "41",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Sparkling Ice",
    brands: "Antelio Plus",
    code: "ST 167",
    lightTransmissionPerc: "60",
    externalReflectionPerc: "22",
    internalReflectionPerc: "23",
    shgc_sf: "0.60",
    shadingCoefficient: "0.68",
    u_value: "2.80",
    lightTransmissionDir: "w,n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e0",
    colourSLCode: "46",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Lumina",
    brands: "Envision Plus",
    code: "SKN 165",
    lightTransmissionPerc: "60",
    externalReflectionPerc: "16",
    internalReflectionPerc: "18",
    shgc_sf: "0.34",
    shadingCoefficient: "0.39",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f3",
    colourSLCode: "65",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Evo-Lite",
    code: "ET 150",
    lightTransmissionPerc: "45",
    externalReflectionPerc: "15",
    internalReflectionPerc: "17",
    shgc_sf: "0.42",
    shadingCoefficient: "0.48",
    u_value: "2.60",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8fd",
    colourSLCode: "75",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Icy Menthol",
    brands: "Nano",
    code: "KT 140",
    lightTransmissionPerc: "37",
    externalReflectionPerc: "23",
    internalReflectionPerc: "12",
    shgc_sf: "0.29",
    shadingCoefficient: "0.33",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf918",
    colourSLCode: "102",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "Xtreme",
    code: "XT 70/33",
    lightTransmissionPerc: "69",
    externalReflectionPerc: "11",
    internalReflectionPerc: "13",
    shgc_sf: "0.33",
    shadingCoefficient: "0.38",
    u_value: "1.50",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf924",
    colourSLCode: "114",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Ivory",
    brands: "AIS Ecosense Enhance ",
    code: "Ivory",
    lightTransmissionPerc: "20",
    externalReflectionPerc: "34",
    internalReflectionPerc: "23",
    shgc_sf: "0.25",
    shadingCoefficient: "0.29",
    u_value: "2.60",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf903",
    colourSLCode: "81",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Citron Frost",
    brands: "Nano",
    code: "KT 464",
    lightTransmissionPerc: "47",
    externalReflectionPerc: "11",
    internalReflectionPerc: "10",
    shgc_sf: "0.33",
    shadingCoefficient: "0.37",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf925",
    colourSLCode: "115",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Nectar",
    brands: "AIS Ecosense Enhance ",
    code: "Nectar",
    lightTransmissionPerc: "32",
    externalReflectionPerc: "23",
    internalReflectionPerc: "21",
    shgc_sf: "0.35",
    shadingCoefficient: "0.40",
    u_value: "2.70",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8fe",
    colourSLCode: "76",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Moonshine",
    brands: "Nano",
    code: "KT 155",
    lightTransmissionPerc: "47",
    externalReflectionPerc: "17",
    internalReflectionPerc: "11",
    shgc_sf: "0.38",
    shadingCoefficient: "0.43",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8fb",
    colourSLCode: "73",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Horizon",
    code: "SCN 145",
    lightTransmissionPerc: "40",
    externalReflectionPerc: "24",
    internalReflectionPerc: "12",
    shgc_sf: "0.37",
    shadingCoefficient: "0.42",
    u_value: "2.50",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf90a",
    colourSLCode: "88",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Shine",
    brands: "Nano Silver",
    code: "KS 138",
    lightTransmissionPerc: "36",
    externalReflectionPerc: "38",
    internalReflectionPerc: "20",
    shgc_sf: "0.26",
    shadingCoefficient: "0.30",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf917",
    colourSLCode: "101",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Neutral",
    brands: "Xtreme",
    code: "XT 60/28",
    lightTransmissionPerc: "60",
    externalReflectionPerc: "14",
    internalReflectionPerc: "17",
    shgc_sf: "0.28",
    shadingCoefficient: "0.33",
    u_value: "1.50",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf920",
    colourSLCode: "110",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Dawn",
    brands: "AIS Ecosense Enhance ",
    code: "Dawn",
    lightTransmissionPerc: "26",
    externalReflectionPerc: "21",
    internalReflectionPerc: "35",
    shgc_sf: "0.33",
    shadingCoefficient: "0.38",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf926",
    colourSLCode: "116",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Nimbus",
    brands: "AIS Ecosense Enhance ",
    code: "Nimbus",
    lightTransmissionPerc: "47",
    externalReflectionPerc: "23",
    internalReflectionPerc: "18",
    shgc_sf: "0.48",
    shadingCoefficient: "0.55",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf92b",
    colourSLCode: "121",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Solaris",
    brands: "AIS Ecosense Enhance ",
    code: "Solaris",
    lightTransmissionPerc: "7",
    externalReflectionPerc: "43",
    internalReflectionPerc: "33",
    shgc_sf: "0.09",
    shadingCoefficient: "0.10",
    u_value: "1.90",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8ff",
    colourSLCode: "77",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Winter Mist",
    brands: "Nano",
    code: "KT 164",
    lightTransmissionPerc: "57",
    externalReflectionPerc: "14",
    internalReflectionPerc: "10",
    shgc_sf: "0.47",
    shadingCoefficient: "0.54",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91f",
    colourSLCode: "109",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Aura",
    brands: "AIS Ecosense Enhance ",
    code: "Aura",
    lightTransmissionPerc: "48",
    externalReflectionPerc: "27",
    internalReflectionPerc: "25",
    shgc_sf: "0.46",
    shadingCoefficient: "0.53",
    u_value: "2.80",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf90b",
    colourSLCode: "89",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Chroma",
    brands: "Nano Silver",
    code: "KS 146",
    lightTransmissionPerc: "42",
    externalReflectionPerc: "33",
    internalReflectionPerc: "19",
    shgc_sf: "0.29",
    shadingCoefficient: "0.34",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf92c",
    colourSLCode: "122",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Spring Plus",
    brands: "AIS Ecosense Enhance ",
    code: "Spring Plus",
    lightTransmissionPerc: "60",
    externalReflectionPerc: "21",
    internalReflectionPerc: "22",
    shgc_sf: "0.59",
    shadingCoefficient: "0.60",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf90c",
    colourSLCode: "90",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Echo",
    brands: "Nano Silver",
    code: "KS 438",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "28",
    internalReflectionPerc: "20",
    shgc_sf: "0.20",
    shadingCoefficient: "0.23",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf910",
    colourSLCode: "94",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Nano Silver Plus",
    code: "KS 130",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "43",
    internalReflectionPerc: "19",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f8",
    colourSLCode: "70",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Harmony",
    code: "Harmony ",
    lightTransmissionPerc: "24",
    externalReflectionPerc: "23",
    internalReflectionPerc: "16",
    shgc_sf: "0.21",
    shadingCoefficient: "0.24",
    u_value: "1.70",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91d",
    colourSLCode: "107",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Clear",
    brands: "Quartz",
    code: "Quartz ",
    lightTransmissionPerc: "40",
    externalReflectionPerc: "11",
    internalReflectionPerc: "11",
    shgc_sf: "0.25",
    shadingCoefficient: "0.29",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf92a",
    colourSLCode: "120",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Snow",
    brands: "AIS Ecosense Enhance ",
    code: "Snow",
    lightTransmissionPerc: "21",
    externalReflectionPerc: "27",
    internalReflectionPerc: "36",
    shgc_sf: "0.28",
    shadingCoefficient: "0.32",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf931",
    colourSLCode: "127",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Comfort",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Comfort",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "25",
    internalReflectionPerc: "14",
    shgc_sf: "0.24",
    shadingCoefficient: "0.28",
    u_value: "1.70",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf932",
    colourSLCode: "128",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Lite Plus",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Lite Plus",
    lightTransmissionPerc: "54",
    externalReflectionPerc: "15",
    internalReflectionPerc: "11",
    shgc_sf: "0.38",
    shadingCoefficient: "0.44",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf913",
    colourSLCode: "97",
    company: "SGG",
    appearance: "Neutral",
    colourShade: "Pristine White",
    brands: "Planitherm",
    code: "PLTT",
    lightTransmissionPerc: "75",
    externalReflectionPerc: "12",
    internalReflectionPerc: "12",
    shgc_sf: "0.57",
    shadingCoefficient: "0.66",
    u_value: "1.80",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf930",
    colourSLCode: "126",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Brook Plus",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Brook Plus",
    lightTransmissionPerc: "33",
    externalReflectionPerc: "39",
    internalReflectionPerc: "21",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.60",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf934",
    colourSLCode: "130",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Priva",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Priva",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "31",
    internalReflectionPerc: "17",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf933",
    colourSLCode: "129",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Platina",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Platina",
    lightTransmissionPerc: "27",
    externalReflectionPerc: "40",
    internalReflectionPerc: "16",
    shgc_sf: "0.21",
    shadingCoefficient: "0.24",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf935",
    colourSLCode: "131",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Radiance",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Radiance",
    lightTransmissionPerc: "47",
    externalReflectionPerc: "22",
    internalReflectionPerc: "14",
    shgc_sf: "0.35",
    shadingCoefficient: "0.40",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf93b",
    colourSLCode: "137",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Natura Advantage",
    brands: "AIS Ecosense Edge ",
    code: "Natura Advantage",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "24",
    internalReflectionPerc: "14",
    shgc_sf: "0.25",
    shadingCoefficient: "0.29",
    u_value: "1.90",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf937",
    colourSLCode: "133",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Vision Pro",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Vision Pro",
    lightTransmissionPerc: "40",
    externalReflectionPerc: "31",
    internalReflectionPerc: "18",
    shgc_sf: "0.28",
    shadingCoefficient: "0.32",
    u_value: "1.60",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf93a",
    colourSLCode: "136",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Natura",
    brands: "AIS Ecosense Edge ",
    code: "Natura",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "28",
    internalReflectionPerc: "16",
    shgc_sf: "0.24",
    shadingCoefficient: "0.28",
    u_value: "2.00",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf93c",
    colourSLCode: "138",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Natura Plus",
    brands: "AIS Ecosense Edge ",
    code: "Natura Plus",
    lightTransmissionPerc: "40",
    externalReflectionPerc: "22",
    internalReflectionPerc: "12",
    shgc_sf: "0.33",
    shadingCoefficient: "0.38",
    u_value: "2.40",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf936",
    colourSLCode: "132",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Vision Plus",
    brands: "AIS Ecosense Exceed ",
    code: "Clear Vision Plus",
    lightTransmissionPerc: "39",
    externalReflectionPerc: "23",
    internalReflectionPerc: "13",
    shgc_sf: "0.29",
    shadingCoefficient: "0.33",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf93d",
    colourSLCode: "139",
    company: "AIS",
    appearance: "Neutral",
    colourShade: "Clear Essence Plus",
    brands: "AIS Ecosense Essence ",
    code: "Clear Essence Plus",
    lightTransmissionPerc: "71",
    externalReflectionPerc: "10",
    internalReflectionPerc: "12",
    shgc_sf: "0.53",
    shadingCoefficient: "0.61",
    u_value: "1.80",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
];

export const blueAppearance = [
  {
    _id: "664f534027f985ae72baf8d9",
    colourSLCode: "39",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Royale Blue",
    brands: "Cool-Lite",
    code: "ST 750",
    lightTransmissionPerc: "29",
    externalReflectionPerc: "11",
    internalReflectionPerc: "21",
    shgc_sf: "0.29",
    shadingCoefficient: "0.34",
    u_value: "2.80",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8c7",
    colourSLCode: "21",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Solar Plus",
    code: "Tru Blue 40",
    lightTransmissionPerc: "44",
    externalReflectionPerc: "10",
    internalReflectionPerc: "3",
    shgc_sf: "0.38",
    shadingCoefficient: "0.45",
    u_value: "3.23",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8c4",
    colourSLCode: "18",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Solar Plus",
    code: "Blue 27",
    lightTransmissionPerc: "28",
    externalReflectionPerc: "12",
    internalReflectionPerc: "4",
    shgc_sf: "0.28",
    shadingCoefficient: "0.33",
    u_value: "3.01",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d8",
    colourSLCode: "38",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Breeze",
    brands: "Cool-Lite",
    code: "ST 736",
    lightTransmissionPerc: "21",
    externalReflectionPerc: "12",
    internalReflectionPerc: "22",
    shgc_sf: "0.24",
    shadingCoefficient: "0.27",
    u_value: "2.70",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8dd",
    colourSLCode: "43",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Ray",
    brands: "Antelio Plus",
    code: "ST 767",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "12",
    internalReflectionPerc: "22",
    shgc_sf: "0.36",
    shadingCoefficient: "0.41",
    u_value: "2.80",
    lightTransmissionDir: "w,n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8ea",
    colourSLCode: "56",
    company: "SGG",
    appearance: "Blue",
    colourShade: "B",
    brands: "Equinox",
    code: "Equinox B",
    lightTransmissionPerc: "19",
    externalReflectionPerc: "8",
    internalReflectionPerc: "10",
    shgc_sf: "0.15",
    shadingCoefficient: "0.17",
    u_value: "1.60",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8b6",
    colourSLCode: "4",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Double Silver",
    code: "Blue 30",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "18",
    internalReflectionPerc: "13",
    shgc_sf: "0.22",
    shadingCoefficient: "0.24",
    u_value: "1.70",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8bb",
    colourSLCode: "9",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Double Silver",
    code: "Blue 40",
    lightTransmissionPerc: "39",
    externalReflectionPerc: "",
    internalReflectionPerc: "",
    shgc_sf: "0.24",
    shadingCoefficient: "0.28",
    u_value: "1.67",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d5",
    colourSLCode: "35",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Green",
    brands: "Cool-Lite",
    code: "ST 450",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "15",
    internalReflectionPerc: "22",
    shgc_sf: "0.33",
    shadingCoefficient: "0.37",
    u_value: "2.80",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f2",
    colourSLCode: "64",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Evo Plus",
    code: "ET 735",
    lightTransmissionPerc: "19",
    externalReflectionPerc: "12",
    internalReflectionPerc: "14",
    shgc_sf: "0.19",
    shadingCoefficient: "0.21",
    u_value: "2.10",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8b7",
    colourSLCode: "5",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Double Silver",
    code: "Blue 50",
    lightTransmissionPerc: "49",
    externalReflectionPerc: "10",
    internalReflectionPerc: "14",
    shgc_sf: "0.30",
    shadingCoefficient: "0.34",
    u_value: "1.67",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d7",
    colourSLCode: "37",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Tranquil Blue",
    brands: "Cool-Lite",
    code: "ST 720",
    lightTransmissionPerc: "12",
    externalReflectionPerc: "16",
    internalReflectionPerc: "30",
    shgc_sf: "0.17",
    shadingCoefficient: "0.19",
    u_value: "2.60",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8c1",
    colourSLCode: "15",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  HD Colors",
    code: "Blue",
    lightTransmissionPerc: "26",
    externalReflectionPerc: "21",
    internalReflectionPerc: "28",
    shgc_sf: "0.40",
    shadingCoefficient: "0.47",
    u_value: "4.58",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8c9",
    colourSLCode: "23",
    company: "GG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "SunGuard  Solar Plus",
    code: "Light Blue 50",
    lightTransmissionPerc: "51",
    externalReflectionPerc: "16",
    internalReflectionPerc: "6",
    shgc_sf: "0.42",
    shadingCoefficient: "0.49",
    u_value: "3.46",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "Clear 4-12 mm",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d6",
    colourSLCode: "36",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Isle",
    brands: "Cool-Lite",
    code: "ST 708",
    lightTransmissionPerc: "5",
    externalReflectionPerc: "20",
    internalReflectionPerc: "36",
    shgc_sf: "0.09",
    shadingCoefficient: "0.11",
    u_value: "1.90",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8da",
    colourSLCode: "40",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Titanium Blue",
    brands: "Cool-Lite",
    code: "STB 120",
    lightTransmissionPerc: "20",
    externalReflectionPerc: "21",
    internalReflectionPerc: "32",
    shgc_sf: "0.24",
    shadingCoefficient: "0.27",
    u_value: "2.70",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8ef",
    colourSLCode: "61",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Orion Blue",
    brands: "Evo",
    code: "ET 725",
    lightTransmissionPerc: "16",
    externalReflectionPerc: "14",
    internalReflectionPerc: "15",
    shgc_sf: "0.16",
    shadingCoefficient: "0.19",
    u_value: "2.00",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf912",
    colourSLCode: "96",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Nano Silver Plus",
    code: "KS 730",
    lightTransmissionPerc: "19",
    externalReflectionPerc: "20",
    internalReflectionPerc: "19",
    shgc_sf: "0.16",
    shadingCoefficient: "0.18",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf928",
    colourSLCode: "118",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Oceanic Blue Plus",
    brands: "AIS Ecosense Enhance ",
    code: "Oceanic Blue Plus",
    lightTransmissionPerc: "21",
    externalReflectionPerc: "21",
    internalReflectionPerc: "29",
    shgc_sf: "0.25",
    shadingCoefficient: "0.29",
    u_value: "2.50",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf929",
    colourSLCode: "119",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Sky",
    brands: "AIS Ecosense Enhance ",
    code: "Sky",
    lightTransmissionPerc: "37",
    externalReflectionPerc: "25",
    internalReflectionPerc: "11",
    shgc_sf: "0.34",
    shadingCoefficient: "0.39",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf905",
    colourSLCode: "83",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Twilight Blue",
    brands: "Nano",
    code: "KT 740",
    lightTransmissionPerc: "24",
    externalReflectionPerc: "12",
    internalReflectionPerc: "12",
    shgc_sf: "0.20",
    shadingCoefficient: "0.22",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf90e",
    colourSLCode: "92",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Ultramarine",
    brands: "Nano Silver",
    code: "KS 738",
    lightTransmissionPerc: "23",
    externalReflectionPerc: "18",
    internalReflectionPerc: "20",
    shgc_sf: "0.18",
    shadingCoefficient: "0.21",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf914",
    colourSLCode: "98",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Satin Blue",
    brands: "Planitherm",
    code: "PLTT B",
    lightTransmissionPerc: "48",
    externalReflectionPerc: "7",
    internalReflectionPerc: "11",
    shgc_sf: "0.35",
    shadingCoefficient: "0.40",
    u_value: "1.80",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf92f",
    colourSLCode: "125",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Blue Berry Plus",
    brands: "AIS Ecosense Exceed ",
    code: "Blue Berry Plus",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "23",
    internalReflectionPerc: "18",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "n,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf908",
    colourSLCode: "86",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Oxy Blue",
    brands: "Nano Blue",
    code: "KBT 130",
    lightTransmissionPerc: "36",
    externalReflectionPerc: "24",
    internalReflectionPerc: "17",
    shgc_sf: "0.30",
    shadingCoefficient: "0.35",
    u_value: "1.80",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf90d",
    colourSLCode: "91",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Celeste",
    brands: "Nano Silver",
    code: "KS 446",
    lightTransmissionPerc: "35",
    externalReflectionPerc: "24",
    internalReflectionPerc: "19",
    shgc_sf: "0.23",
    shadingCoefficient: "0.26",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf90f",
    colourSLCode: "93",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Lagoon",
    brands: "Nano Silver",
    code: "KS 746",
    lightTransmissionPerc: "27",
    externalReflectionPerc: "16",
    internalReflectionPerc: "19",
    shgc_sf: "0.20",
    shadingCoefficient: "0.23",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf916",
    colourSLCode: "100",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Xtreme",
    code: "XT 50/22",
    lightTransmissionPerc: "46",
    externalReflectionPerc: "16",
    internalReflectionPerc: "18",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.50",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf907",
    colourSLCode: "85",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Horizon",
    brands: "Nano",
    code: "KT 764",
    lightTransmissionPerc: "36",
    externalReflectionPerc: "8",
    internalReflectionPerc: "9",
    shgc_sf: "0.29",
    shadingCoefficient: "0.33",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f5",
    colourSLCode: "67",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Evo-Lite",
    code: "ET 750",
    lightTransmissionPerc: "29",
    externalReflectionPerc: "9",
    internalReflectionPerc: "16",
    shgc_sf: "0.27",
    shadingCoefficient: "0.31",
    u_value: "2.60",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf909",
    colourSLCode: "87",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue Frost",
    brands: "Nano Blue",
    code: "KBT 140",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "21",
    internalReflectionPerc: "22",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.70",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f9",
    colourSLCode: "71",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Horizon",
    code: "SCN 745",
    lightTransmissionPerc: "26",
    externalReflectionPerc: "12",
    internalReflectionPerc: "11",
    shgc_sf: "0.25",
    shadingCoefficient: "0.28",
    u_value: "2.50",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf906",
    colourSLCode: "84",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Misty Blue",
    brands: "Nano",
    code: "KT 755",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "10",
    internalReflectionPerc: "10",
    shgc_sf: "0.24",
    shadingCoefficient: "0.28",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91a",
    colourSLCode: "104",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Blu De",
    code: "Blu De ",
    lightTransmissionPerc: "46",
    externalReflectionPerc: "31",
    internalReflectionPerc: "24",
    shgc_sf: "0.30",
    shadingCoefficient: "0.35",
    u_value: "1.90",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f6",
    colourSLCode: "68",
    company: "SGG",
    appearance: "Blue",
    colourShade: "Blue",
    brands: "Harmony",
    code: "Harmony ",
    lightTransmissionPerc: "15",
    externalReflectionPerc: "12",
    internalReflectionPerc: "16",
    shgc_sf: "0.15",
    shadingCoefficient: "0.17",
    u_value: "1.70",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf927",
    colourSLCode: "117",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Oasis",
    brands: "AIS Ecosense Enhance ",
    code: "Oasis",
    lightTransmissionPerc: "27",
    externalReflectionPerc: "17",
    internalReflectionPerc: "29",
    shgc_sf: "0.31",
    shadingCoefficient: "0.36",
    u_value: "2.50",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf938",
    colourSLCode: "134",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Electric Blue",
    brands: "AIS Ecosense Exceed ",
    code: "Electric Blue",
    lightTransmissionPerc: "36",
    externalReflectionPerc: "23",
    internalReflectionPerc: "17",
    shgc_sf: "0.31",
    shadingCoefficient: "0.36",
    u_value: "1.80",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf939",
    colourSLCode: "135",
    company: "AIS",
    appearance: "Blue",
    colourShade: "Tropic Blue",
    brands: "AIS Ecosense Exceed ",
    code: "Tropic Blue",
    lightTransmissionPerc: "44",
    externalReflectionPerc: "16",
    internalReflectionPerc: "16",
    shgc_sf: "0.34",
    shadingCoefficient: "0.39",
    u_value: "1.80",
    lightTransmissionDir: "",
    shadingCoefficientDir: "n",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
];

export const metalAppearance = [
  {
    _id: "664f534027f985ae72baf8e8",
    colourSLCode: "54",
    company: "SGG",
    appearance: "Metal",
    colourShade: "Stellar",
    brands: "Envision Plus",
    code: "SKN 765",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "9",
    internalReflectionPerc: "17",
    shgc_sf: "0.24",
    shadingCoefficient: "0.27",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e9",
    colourSLCode: "55",
    company: "SGG",
    appearance: "Metal",
    colourShade: "",
    brands: "Envision Plus",
    code: "SKN 776",
    lightTransmissionPerc: "44",
    externalReflectionPerc: "8",
    internalReflectionPerc: "13",
    shgc_sf: "0.26",
    shadingCoefficient: "0.30",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8e5",
    colourSLCode: "51",
    company: "SGG",
    appearance: "Metal",
    colourShade: "",
    brands: "Envision Plus",
    code: "SKN 476",
    lightTransmissionPerc: "57",
    externalReflectionPerc: "10",
    internalReflectionPerc: "14",
    shgc_sf: "0.30",
    shadingCoefficient: "0.35",
    u_value: "1.50",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8c2",
    colourSLCode: "16",
    company: "GG",
    appearance: "Metal",
    colourShade: "Bronze",
    brands: "SunGuard  HD Colors",
    code: "Bronze",
    lightTransmissionPerc: "24",
    externalReflectionPerc: "14",
    internalReflectionPerc: "2",
    shgc_sf: "0.35",
    shadingCoefficient: "0.41",
    u_value: "4.10",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf92e",
    colourSLCode: "124",
    company: "AIS",
    appearance: "Metal",
    colourShade: "Aurelia",
    brands: "AIS Ecosense Exceed ",
    code: "Aurelia",
    lightTransmissionPerc: "30",
    externalReflectionPerc: "41",
    internalReflectionPerc: "46",
    shgc_sf: "0.24",
    shadingCoefficient: "0.28",
    u_value: "1.60",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf900",
    colourSLCode: "78",
    company: "SGG",
    appearance: "Metal",
    colourShade: "",
    brands: "Nano",
    code: "KT 430",
    lightTransmissionPerc: "26",
    externalReflectionPerc: "16",
    internalReflectionPerc: "13",
    shgc_sf: "0.20",
    shadingCoefficient: "0.22",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91b",
    colourSLCode: "105",
    company: "SGG",
    appearance: "Metal",
    colourShade: "Copper",
    brands: "Kopper",
    code: "Kopper",
    lightTransmissionPerc: "28",
    externalReflectionPerc: "12",
    internalReflectionPerc: "35",
    shgc_sf: "0.35",
    shadingCoefficient: "0.40",
    u_value: "2.50",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8fc",
    colourSLCode: "74",
    company: "SGG",
    appearance: "Metal",
    colourShade: "Zephyr",
    brands: "Nano",
    code: "KT 130",
    lightTransmissionPerc: "31",
    externalReflectionPerc: "22",
    internalReflectionPerc: "14",
    shgc_sf: "0.25",
    shadingCoefficient: "0.29",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf904",
    colourSLCode: "82",
    company: "SGG",
    appearance: "Metal",
    colourShade: "",
    brands: "Nano",
    code: "KT 730",
    lightTransmissionPerc: "20",
    externalReflectionPerc: "11",
    internalReflectionPerc: "13",
    shgc_sf: "0.18",
    shadingCoefficient: "0.20",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91e",
    colourSLCode: "108",
    company: "SGG",
    appearance: "Metal",
    colourShade: "Pink",
    brands: "Rosa",
    code: "Rosa",
    lightTransmissionPerc: "28",
    externalReflectionPerc: "11",
    internalReflectionPerc: "36",
    shgc_sf: "0.36",
    shadingCoefficient: "0.41",
    u_value: "2.50",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf91c",
    colourSLCode: "106",
    company: "SGG",
    appearance: "Metal",
    colourShade: "Gold",
    brands: "Midas Gold",
    code: "Midas Gold",
    lightTransmissionPerc: "24",
    externalReflectionPerc: "28",
    internalReflectionPerc: "16",
    shgc_sf: "0.26",
    shadingCoefficient: "0.30",
    u_value: "2.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf919",
    colourSLCode: "103",
    company: "SGG",
    appearance: "Metal",
    colourShade: "",
    brands: "Aurum",
    code: "Aurum",
    lightTransmissionPerc: "22",
    externalReflectionPerc: "23",
    internalReflectionPerc: "34",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w,e",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf923",
    colourSLCode: "113",
    company: "AIS",
    appearance: "Metal",
    colourShade: "Gold",
    brands: "AIS Ecosense Enhance ",
    code: "Gold",
    lightTransmissionPerc: "44",
    externalReflectionPerc: "27",
    internalReflectionPerc: "29",
    shgc_sf: "0.48",
    shadingCoefficient: "0.55",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
];

export const greenAppearance = [
  {
    _id: "664f534027f985ae72baf8d3",
    colourSLCode: "33",
    company: "SGG",
    appearance: "Green",
    colourShade: "Aqua Marine",
    brands: "Cool-Lite",
    code: "ST 420",
    lightTransmissionPerc: "15",
    externalReflectionPerc: "24",
    internalReflectionPerc: "30",
    shgc_sf: "0.18",
    shadingCoefficient: "0.20",
    u_value: "2.60",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8eb",
    colourSLCode: "57",
    company: "SGG",
    appearance: "Green",
    colourShade: "G",
    brands: "Equinox",
    code: "Equinox G",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "10",
    internalReflectionPerc: "10",
    shgc_sf: "0.16",
    shadingCoefficient: "0.19",
    u_value: "1.60",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8d2",
    colourSLCode: "32",
    company: "SGG",
    appearance: "Green",
    colourShade: "Deep Green",
    brands: "Cool-Lite",
    code: "ST 408",
    lightTransmissionPerc: "7",
    externalReflectionPerc: "31",
    internalReflectionPerc: "36",
    shgc_sf: "0.09",
    shadingCoefficient: "0.11",
    u_value: "1.90",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8b8",
    colourSLCode: "6",
    company: "GG",
    appearance: "Green",
    colourShade: "Green",
    brands: "SunGuard  Double Silver",
    code: "Green 40",
    lightTransmissionPerc: "40",
    externalReflectionPerc: "21",
    internalReflectionPerc: "18",
    shgc_sf: "0.26",
    shadingCoefficient: "0.29",
    u_value: "1.65",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8cd",
    colourSLCode: "27",
    company: "GG",
    appearance: "Green",
    colourShade: "Green",
    brands: "SunGuard  Solar Plus",
    code: "Sea Green 35",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "19",
    internalReflectionPerc: "5",
    shgc_sf: "0.35",
    shadingCoefficient: "0.41",
    u_value: "3.15",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "Clear 4-12 mm",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8b5",
    colourSLCode: "3",
    company: "GG",
    appearance: "Green",
    colourShade: "Green",
    brands: "SunGuard  Double Silver",
    code: "Aquamarine 35",
    lightTransmissionPerc: "34",
    externalReflectionPerc: "20",
    internalReflectionPerc: "16",
    shgc_sf: "0.23",
    shadingCoefficient: "0.26",
    u_value: "1.72",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8d4",
    colourSLCode: "34",
    company: "SGG",
    appearance: "Green",
    colourShade: "Turquoise",
    brands: "Cool-Lite",
    code: "ST 436",
    lightTransmissionPerc: "28",
    externalReflectionPerc: "17",
    internalReflectionPerc: "22",
    shgc_sf: "0.26",
    shadingCoefficient: "0.30",
    u_value: "2.70",
    lightTransmissionDir: "n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8cb",
    colourSLCode: "25",
    company: "GG",
    appearance: "Green",
    colourShade: "Green",
    brands: "SunGuard  Solar Plus",
    code: "Green 50",
    lightTransmissionPerc: "54",
    externalReflectionPerc: "16",
    internalReflectionPerc: "4",
    shgc_sf: "0.44",
    shadingCoefficient: "0.52",
    u_value: "3.48",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "Clear 4-12 mm",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8ee",
    colourSLCode: "60",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green Aura",
    brands: "Evo",
    code: "ET 425",
    lightTransmissionPerc: "21",
    externalReflectionPerc: "21",
    internalReflectionPerc: "15",
    shgc_sf: "0.18",
    shadingCoefficient: "0.21",
    u_value: "2.00",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8dc",
    colourSLCode: "42",
    company: "SGG",
    appearance: "Green",
    colourShade: "Emerald Glaze",
    brands: "Antelio Plus",
    code: "ST 467",
    lightTransmissionPerc: "50",
    externalReflectionPerc: "17",
    internalReflectionPerc: "23",
    shgc_sf: "0.40",
    shadingCoefficient: "0.46",
    u_value: "2.80",
    lightTransmissionDir: "w,n,e",
    shadingCoefficientDir: "s",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f1",
    colourSLCode: "63",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green",
    brands: "Evo Plus",
    code: "ET 435",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "18",
    internalReflectionPerc: "14",
    shgc_sf: "0.21",
    shadingCoefficient: "0.24",
    u_value: "2.10",
    lightTransmissionDir: "w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8f4",
    colourSLCode: "66",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green",
    brands: "Evo-Lite",
    code: "ET 450",
    lightTransmissionPerc: "37",
    externalReflectionPerc: "12",
    internalReflectionPerc: "17",
    shgc_sf: "0.30",
    shadingCoefficient: "0.34",
    u_value: "2.60",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf8fa",
    colourSLCode: "72",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green",
    brands: "Horizon",
    code: "SCN 445",
    lightTransmissionPerc: "34",
    externalReflectionPerc: "18",
    internalReflectionPerc: "11",
    shgc_sf: "0.27",
    shadingCoefficient: "0.31",
    u_value: "2.50",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf911",
    colourSLCode: "95",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green",
    brands: "Nano Silver Plus",
    code: "KS 430",
    lightTransmissionPerc: "25",
    externalReflectionPerc: "31",
    internalReflectionPerc: "19",
    shgc_sf: "0.18",
    shadingCoefficient: "0.20",
    u_value: "1.60",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf901",
    colourSLCode: "79",
    company: "SGG",
    appearance: "Green",
    colourShade: "Tropica Green",
    brands: "Nano",
    code: "KT 440",
    lightTransmissionPerc: "31",
    externalReflectionPerc: "17",
    internalReflectionPerc: "12",
    shgc_sf: "0.22",
    shadingCoefficient: "0.25",
    u_value: "1.80",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf915",
    colourSLCode: "99",
    company: "SGG",
    appearance: "Green",
    colourShade: "Mint Green",
    brands: "Planitherm",
    code: "PLTT G",
    lightTransmissionPerc: "62",
    externalReflectionPerc: "9",
    internalReflectionPerc: "11",
    shgc_sf: "0.40",
    shadingCoefficient: "0.46",
    u_value: "1.80",
    lightTransmissionDir: "s,w",
    shadingCoefficientDir: "w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf921",
    colourSLCode: "111",
    company: "AIS",
    appearance: "Green",
    colourShade: "Eco",
    brands: "AIS Ecosense Enhance ",
    code: "Eco",
    lightTransmissionPerc: "45",
    externalReflectionPerc: "23",
    internalReflectionPerc: "12",
    shgc_sf: "0.44",
    shadingCoefficient: "0.51",
    u_value: "2.80",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8f7",
    colourSLCode: "69",
    company: "SGG",
    appearance: "Green",
    colourShade: "Green",
    brands: "Harmony",
    code: "Harmony ",
    lightTransmissionPerc: "20",
    externalReflectionPerc: "17",
    internalReflectionPerc: "16",
    shgc_sf: "0.16",
    shadingCoefficient: "0.19",
    u_value: "1.70",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf92d",
    colourSLCode: "123",
    company: "AIS",
    appearance: "Green",
    colourShade: "Zest",
    brands: "AIS Ecosense Enhance ",
    code: "Zest",
    lightTransmissionPerc: "38",
    externalReflectionPerc: "25",
    internalReflectionPerc: "11",
    shgc_sf: "0.38",
    shadingCoefficient: "0.44",
    u_value: "2.70",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf902",
    colourSLCode: "80",
    company: "SGG",
    appearance: "Green",
    colourShade: "Olive",
    brands: "Nano",
    code: "KT 455",
    lightTransmissionPerc: "39",
    externalReflectionPerc: "13",
    internalReflectionPerc: "10",
    shgc_sf: "0.27",
    shadingCoefficient: "0.31",
    u_value: "1.90",
    lightTransmissionDir: "s,w,e",
    shadingCoefficientDir: "s,w",
    availableThickness: "4, 5, 6mm",
    inNeutralShades: "10, 12mm ",
    spRequest: "8mm",
  },
  {
    _id: "664f534027f985ae72baf922",
    colourSLCode: "112",
    company: "AIS",
    appearance: "Green",
    colourShade: "Fern",
    brands: "AIS Ecosense Enhance ",
    code: "Fern",
    lightTransmissionPerc: "24",
    externalReflectionPerc: "36",
    internalReflectionPerc: "10",
    shgc_sf: "0.27",
    shadingCoefficient: "0.31",
    u_value: "2.70",
    lightTransmissionDir: "s",
    shadingCoefficientDir: "w,e",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
];

export const silverAppearance = [
  {
    _id: "664f534027f985ae72baf8b4",
    colourSLCode: "2",
    company: "GG",
    appearance: "Silver",
    colourShade: "Silver",
    brands: "SunGuard  High Performance",
    code: "Silver 35",
    lightTransmissionPerc: "23",
    externalReflectionPerc: "42",
    internalReflectionPerc: "27",
    shgc_sf: "0.22",
    shadingCoefficient: "0.26",
    u_value: "1.27",
    lightTransmissionDir: "",
    shadingCoefficientDir: "",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
  {
    _id: "664f534027f985ae72baf8bf",
    colourSLCode: "13",
    company: "GG",
    appearance: "Silver",
    colourShade: "Silver",
    brands: "SunGuard  Solar",
    code: "Silver 20",
    lightTransmissionPerc: "20",
    externalReflectionPerc: "31",
    internalReflectionPerc: "25",
    shgc_sf: "0.29",
    shadingCoefficient: "0.35",
    u_value: "3.73",
    lightTransmissionDir: "n",
    shadingCoefficientDir: "s",
    availableThickness: "",
    inNeutralShades: "",
    spRequest: "",
  },
];
