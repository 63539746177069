import React from "react";
import "./CalculatorBanner.scss";
import BannerImg from "../../../assets/images/calculator-img.png";
export default function CalculatorBanner() {
  return (
    <div className="calculatorBanner-section">
      <div className="calculatorBanner-details">
        <div className="left-side-img">
          <img src={BannerImg} alt="BannerImg" />
        </div>
        <h1>Select your glass panels smartly </h1>
      </div>
    </div>
  );
}
