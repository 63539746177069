import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import "./Projects.scss";
import React, { useEffect, useRef } from "react";
import RioEmpireExternal from "../../assets/images/rio-empire-external.png";
import OberoiEnigma from "../../assets/images/oberoi-enigma.png";
import NiraliHospital from "../../assets/images/Nirali Cancer Hospital – Navsari.jpg";
import ProjectBanner from "../../assets/images/project-banner.png";
import WestMumbai from "../../assets/images/72 West - Mumbai.webp";
import AkashExpressions from "../../assets/images/Aakash Expressions - Surat.avif";
import CasaRiver from "../../assets/images/Casa Rivera - Surat.jpg";
import DMDCosmos from "../../assets/images/DMD Cosmos - Surat.jpg";
import DMDPacific from "../../assets/images/DMD Paccific - Surat.jpeg";
import DMDPark from "../../assets/images/DMD Park - Surat.jpg";
import DMDSpringValley from "../../assets/images/DMD Spring Valley - Surat.avif";
import GionaMumbai from "../../assets/images/Giona - Mumbai.jpg";
import HappyCelebrity from "../../assets/images/Happy Celebrity Green - Surat.jpeg";
import HappyExcellencia from "../../assets/images/Happy Excellencia - Surat.jpg";
import RajhansAltezza from "../../assets/images/Rajhans Altezza - Surat.jpg";
import RajhansCosmic from "../../assets/images/Rajhans Cosmic - Surat.avif";
import RajhansGrandezza from "../../assets/images/Rajhans Grandezza - Surat.avif";
import RajhansRoyalton from "../../assets/images/Rajhans Royalton - Surat.jpeg";
import RajhansSynfonia from "../../assets/images/Rajhans Synfonia - Surat.avif";
import RajhansTrionzza from "../../assets/images/Rajhans Trionzza - Surat.jpg";
import RajhansZion from "../../assets/images/Rajhans Zion - Surat.jpeg";
import Audi from "../../assets/images/Audi Showroom - Surat.jpg";
import AvadhSurat from "../../assets/images/Avadh Arena  - Surat.jpg";
import BusDepotBharuch from "../../assets/images/Bharuch Bus Depot - Bharuch.jpg";
import CanalWalker from "../../assets/images/CNK Cannal Walk Shoppers – Surat.jpg";
import VIPGalleria from "../../assets/images/CNK VIP Galleria – Surat.jpg";
import Luxuria from "../../assets/images/Luxuria Business Hub - Surat.jpg";
import Marvella4Point from "../../assets/images/Marvella Four Point – Surat.jpg";
import RajhansMontessa from "../../assets/images/Rajhans Montessa - Surat.jpg";
import AMNS from "../../assets/images/AMNS – Surat.jpg";
import BOA from "../../assets/images/Bank of America - Ahmedabad.avif";
import Infosys from "../../assets/images/Infosys Limited – Pune.jpeg";
import Kiran from "../../assets/images/Kiran Diamonds – Surat.webp";
import KiranHospital from "../../assets/images/Kiran-Hospital-Surat.jpeg";
import Mahavir from "../../assets/images/Mahavir Hospital - Surat.jpeg";
import MerilLS from "../../assets/images/Meril Life Science – Vapi.jpg";
import RajhansCH from "../../assets/images/Rajhans Corporate House – Surat.jpg";
import Kratos from "../../assets/images/Kratos Club – Surat.avif";
import Paraizo from "../../assets/images/Paraizo Club – Surat.avif";
import RajhansBelliza from "../../assets/images/Rajhans Belliza - Surat.jpg";
import RajhansFeriado from "../../assets/images/Rajhans Feriado - Surat.avif";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Projects() {
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const [value, setValue] = React.useState(0);
  const isMobile = useMediaQuery("(max-width:767px)");

  const residentialData = [
    {
      imageURL: RioEmpireExternal,
      projectName: "Rio Empire - External",
      projectLocation: "Surat",
    },
    {
      imageURL: OberoiEnigma,
      projectName: "Oberoi Enigma - Mumbai",
      projectLocation: "Mumbai",
    },
    {
      imageURL: WestMumbai,
      projectName: "72 West - Mumbai",
      projectLocation: "Mumbai",
    },
    {
      imageURL: AkashExpressions,
      projectName: "Aakash Expressions - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: CasaRiver,
      projectName: "Casa Rivera - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: DMDCosmos,
      projectName: "DMD Cosmos - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: DMDPacific,
      projectName: "DMD Paccific - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: DMDPark,
      projectName: "DMD Park - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: DMDSpringValley,
      projectName: "DMD Spring Valley - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: GionaMumbai,
      projectName: "Giona - Mumbai",
      projectLocation: "Mumbai",
    },
    {
      imageURL: HappyCelebrity,
      projectName: "Happy Celebrity Green - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: HappyExcellencia,
      projectName: "Happy Excellencia - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansAltezza,
      projectName: "Rajhans Altezza - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansCosmic,
      projectName: "Rajhans Cosmic - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansGrandezza,
      projectName: "Rajhans Grandezza - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansRoyalton,
      projectName: "Rajhans Royalton - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansSynfonia,
      projectName: "Rajhans Synfonia - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansTrionzza,
      projectName: "Rajhans Trionzza - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansZion,
      projectName: "Rajhans Zion - Surat",
      projectLocation: "Surat",
    },
  ];

  const commercialData = [
    {
      imageURL: Audi,
      projectName: "Audi Showroom",
      projectLocation: "Surat",
    },
    {
      imageURL: AvadhSurat,
      projectName: "Avadh Arena",
      projectLocation: "Surat",
    },
    {
      imageURL: BusDepotBharuch,
      projectName: "Bharuch Bus Depot",
      projectLocation: "Bharuch",
    },
    {
      imageURL: CanalWalker,
      projectName: "CNK Cannal Walk Shoppers - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: VIPGalleria,
      projectName: "CNK VIP Galleria - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: Luxuria,
      projectName: "Luxuria Business Hub - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: Marvella4Point,
      projectName: "Marvella Four Point - Surat",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansMontessa,
      projectName: "Rajhans Montessa - Surat",
      projectLocation: "Surat",
    },
  ];

  const institutionalData = [
    {
      imageURL: AMNS,
      projectName: "AMNS",
      projectLocation: "Surat",
    },
    {
      imageURL: BOA,
      projectName: "Bank of America",
      projectLocation: "Ahmedabad",
    },
    {
      imageURL: Infosys,
      projectName: "Infosys Limited",
      projectLocation: "Pune",
    },
    {
      imageURL: Kiran,
      projectName: "Kiran Diamonds",
      projectLocation: "Surat",
    },
    {
      imageURL: KiranHospital,
      projectName: "Kiran Hospital",
      projectLocation: "Surat",
    },
    {
      imageURL: Mahavir,
      projectName: "Mahavir Hospital",
      projectLocation: "Surat",
    },
    {
      imageURL: MerilLS,
      projectName: "Meril Life Science",
      projectLocation: "Vapi",
    },
    {
      imageURL: NiraliHospital,
      projectName: "Nirali Cancer Hospital",
      projectLocation: "Navsari",
    },
    {
      imageURL: RajhansCH,
      projectName: "Rajhans Corporate House",
      projectLocation: "Surat",
    },
  ];

  const leisureData = [
    {
      imageURL: Kratos,
      projectName: "Kratos Club",
      projectLocation: "Surat",
    },
    {
      imageURL: Paraizo,
      projectName: "Paraizo Club",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansBelliza,
      projectName: "Rajhans Belliza",
      projectLocation: "Surat",
    },
    {
      imageURL: RajhansFeriado,
      projectName: "Rajhans Feriado",
      projectLocation: "Surat",
    },
  ];

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);
  };

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (localStorage.getItem("project")) {
      scrollToTarget();
      setValue(Number(localStorage.getItem("project")));
    } else {
      setValue(0);
    }
  }, []);

  return (
    <div className="main-project-wrapper">
      <div className="project-content-wrapper">
        <div className="project-banner-wrapper">
          <div className="banner-content-wrapper">
            <img className="banner-image" src={ProjectBanner} alt="" />
            <div className="banner-text-wrapper">
              <div className="banner-header-text">
                <h2 className="welcome-text">Transforming Spaces with</h2>
                <h2 className="welcome-text">Innovative Glass Solutions</h2>
                <p>Unleash the Potential of Glass in Your Project</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="project-description-content">
            <p>
              JGS Glass Industries is proud to showcase a diverse portfolio of
              projects where we've transformed spaces with our exceptional glass
              solutions. Explore our extensive gallery to discover how we've
              collaborated with leading architects, builders, and designers to
              bring their visions to life.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="project-category-filter-wrapper" ref={targetRef}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                variant={isMobile ? "scrollable" : "standard"}
                scrollButtons="true"
                centered
                value={value}
                onChange={handleChange}
                sx={{
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#393184",
                  },
                  ".MuiTab-root": {
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#393184 !important",
                      fontSize: "16px",
                    },
                  },
                }}
              >
                <Tab label="Residential" {...a11yProps(0)} />
                <Tab label="Commercial" {...a11yProps(1)} />
                <Tab label="Institutional" {...a11yProps(2)} />
                <Tab label="Leisure" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div className="tab-panel-grid">
                {residentialData?.map((data, index) => {
                  return (
                    <div className="tab-panel-grid-item">
                      <div className="image-wrapper">
                        <img src={data.imageURL} alt="" />
                      </div>
                      <div className="grid-item-content">
                        <span className="item-title">{data.projectName}</span>
                        <span className="item-location">
                          {data.projectLocation}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div className="tab-panel-grid">
                {commercialData?.map((cData, index) => {
                  return (
                    <div className="tab-panel-grid-item" key={index}>
                      <div className="image-wrapper">
                        <img src={cData.imageURL} alt="" />
                      </div>
                      <div className="grid-item-content">
                        <span className="item-title">{cData.projectName}</span>
                        <span className="item-location">
                          {cData.projectLocation}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div className="tab-panel-grid">
                {institutionalData?.map((iData, index) => {
                  return (
                    <div className="tab-panel-grid-item">
                      <div className="image-wrapper">
                        <img src={iData.imageURL} alt="" />
                      </div>
                      <div className="grid-item-content">
                        <span className="item-title">{iData.projectName}</span>
                        <span className="item-location">
                          {iData.projectLocation}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <div className="tab-panel-grid">
                {leisureData?.map((iData, index) => {
                  return (
                    <div className="tab-panel-grid-item">
                      <div className="image-wrapper">
                        <img src={iData.imageURL} alt="" />
                      </div>
                      <div className="grid-item-content">
                        <span className="item-title">{iData.projectName}</span>
                        <span className="item-location">
                          {iData.projectLocation}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </CustomTabPanel>
          </div>
        </div>

        <div className="container">
          <div className="project-inspire-wrapper">
            <div className="inspire-grid-item">
              <div className="grid-title">Get Inspired by JGS Expertise</div>
              <div className="grid-description">
                Browse through our project gallery and discover the endless
                possibilities JGS Glass offers. From sleek, modern facades to
                light-filled interior spaces, we have the expertise and
                experience to elevate any project with innovative and
                sustainable glass solutions.
              </div>
            </div>
            <div className="inspire-grid-item">
              <div className="grid-title">Ready to Discuss Your Project?</div>
              <div className="grid-description">
                If you have a vision for incorporating glass into your upcoming
                project, we'd love to hear from you! Contact JGS Glass
                Industries today, and our team of experts will be happy to
                discuss your specific needs and recommend the perfect glass
                solutions to bring your project to fruition.
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="contact-us-wrapper">
            <button onClick={() => navigate("/contact-us")}>Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
