import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
} from "@mui/material";
import "./Calculator.scss";
import CalculatorBanner from "./CalculatorBanner/CalculatorBanner";
import GlassConfiguration from "./GlassConfiguration/GlassConfiguration";
import CalcGlass from "../../assets/images/calc-glass.png";
import CalcGlassLaminated from "../../assets/images/calc-glass-laminated.png";
import axios from "axios";
import { citiesData } from "../../constants/cities";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SouthFacade from "./SouthFacade/SouthFacade";
import EastFacade from "./EastFacade/EastFacade";
import NorthFacade from "./NorthFacade/NorthFacade";
import WestFacade from "./WestFacade/WestFacade";

function Calculator() {
  const sliderRef1 = useRef(null);
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const [selectedCity, setSelectedCity] = useState("");
  const [weatherValue, setWeatherValue] = useState("");
  const [outerGlassThickness, setOuterGlassThickness] = useState({
    single: {
      glass1Thickness: {
        top: 70,
        left: 70,
      },
      laminatedThickness: {
        top: 90,
        left: 100,
      },
      glass2Thickness: {
        top: 110,
        left: 120,
      },
    },
    double: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
    triple: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
  });
  const [middleGlassThickness, setMiddleGlassThickness] = useState({
    single: {
      glass1Thickness: {
        top: 20,
        left: 30,
      },
      laminatedThickness: {
        top: 30,
        left: 50,
      },
      glass2Thickness: {
        top: 40,
        left: 56,
      },
    },
    double: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
    triple: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
  });
  const [innerGlassThickness, setInnerGlassThickness] = useState({
    single: {
      glass1Thickness: {
        top: 10,
        left: 20,
      },
      laminatedThickness: {
        top: 50,
        left: 90,
      },
      glass2Thickness: {
        top: 60,
        left: 96,
      },
    },
    double: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
    triple: {
      glass1Thickness: {
        top: 0,
        left: 10,
      },
      laminatedThickness: {
        top: 10,
        left: 30,
      },
      glass2Thickness: {
        top: 18,
        left: 36,
      },
    },
  });
  const [isSingleLaminated, setIsSingleLaminated] = useState(false);
  const [isDoubleLaminated, setIsDoubleLaminated] = useState(false);
  const [isTripleLaminated, setIsTripleLaminated] = useState(false);
  const [formData, setFormData] = useState({
    naturalLight: 9,
    savingElectricity: 9,
    asetheticLook: 9,
    soundProofing: 9,
    safety: 9,
    isStormCyclone: "No",
    soundZone: 1,
    cityName: "",
    exteriorTemp: "",
    interiorTemp: "",
    electricityCost: "",
    hrsACPerDay: "",
    hrsACPerAnnum: "",
    facadeFacing: "S",
  });
  const [facadeRecommended, setFacadeRecommended] = useState("");
  const [layer, setLayer] = useState("Single");
  const [previousValue, setPreviousValue] = useState(0);

  const handleFacadeRecommendation = () => {
    console.log("Form", formData);
    if (
      formData.savingElectricity < 5 &&
      formData.safety < 9 &&
      formData.asetheticLook < 9 &&
      formData.soundProofing < 9
    ) {
      setFacadeRecommended("Clear Toughen Glass");
    } else if (
      formData.safety >= 9 &&
      formData.naturalLight >= 5 &&
      (formData.facadeFacing === "S" ||
        formData.facadeFacing === "W" ||
        formData.facadeFacing === "E" ||
        formData.asetheticLook > 6)
    ) {
      setFacadeRecommended("DGU with 1 Panel Reflective & Laminated");
    } else if (
      (formData.facadeFacing === "S" ||
        formData.facadeFacing === "W" ||
        formData.facadeFacing === "E" ||
        formData.asetheticLook > 6) &&
      formData.safety <= 9
    ) {
      setFacadeRecommended("DGU with 1 Panel Reflective");
    } else {
      setFacadeRecommended("DGU with Clear Glass");
    }
  };

  const handleCityChange = (cityName) => {
    setSelectedCity(cityName);
    setFormData((prevFormData) => ({
      ...prevFormData,
      cityName: cityName,
    }));

    axios
      .post(
        `https://api.weatherapi.com/v1/current.json?q=${cityName}&key=a51e4e093ded4d8787f144851242305`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
      .then((response) => {
        console.log("temp response", response.data.current.temp_c);
        if (response.data.current) {
          setWeatherValue(response.data.current.temp_c);
          setFormData((prevFormData) => ({
            ...prevFormData,
            exteriorTemp: response.data.current.temp_c,
          }));
          setTimeout(() => {
            handleFacadeRecommendation();
            setActiveStep(3);
            setCompletedSteps([...completedSteps, 2]);
          }, 2000);
        }
      })
      .catch((error) => {
        setWeatherValue("");
        console.log("error", error);
      });
  };

  const handleSingleGlass = (thickness, glassType) => {
    setPreviousValue(thickness);
    if (glassType === "laminated") {
      setOuterGlassThickness((prev) => ({
        ...prev,
        single: {
          ...prev.single,
          laminatedThickness: {
            top:
              thickness > previousValue
                ? prev.single.laminatedThickness.top + thickness
                : prev.single.laminatedThickness.top - thickness,
            left:
              thickness > previousValue
                ? prev.single.laminatedThickness.left + thickness
                : prev.single.laminatedThickness.left - thickness,
          },
        },
      }));
    }
    if (glassType === "glass2") {
      setOuterGlassThickness((prev) => ({
        ...prev,
        single: {
          ...prev.single,
          glass2Thickness: {
            top:
              thickness > previousValue
                ? prev.single.glass2Thickness.top + thickness
                : prev.single.glass2Thickness.top - thickness,
            left:
              thickness > previousValue
                ? prev.single.glass2Thickness.left + thickness
                : prev.single.glass2Thickness.left - thickness,
          },
        },
      }));
    }
  };

  // useEffect(() => {
  //   if (activeStep === 3) {
  //     handleFacadeRecommendation();
  //   }
  // }, [activeStep]);

  return (
    <div className="main-calculator-page-wrapper">
      <CalculatorBanner />
      <GlassConfiguration />
      <div className="container">
        <div className="main-calculator-wrapper">
          <Card
            sx={{
              borderRadius: "30px",
              padding: "30px 50px",
            }}
          >
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <div className="stepper-alignment">
                  <div className="stepname">
                    <span>Step 1</span>

                    <div
                      className={`box-alignment ${
                        completedSteps.includes(1) ? "active-box" : ""
                      }`}
                      onClick={() => {
                        setActiveStep(1);
                      }}
                    >
                      <p>1</p>
                    </div>
                  </div>
                  <div
                    className={`stepper-line ${
                      completedSteps.includes(1) ? "greenBar" : ""
                    }`}
                  ></div>

                  <div className="stepname">
                    <span>Step 2</span>
                    <div
                      className={`box-alignment ${
                        completedSteps.includes(2) ? "active-box" : ""
                      }`}
                      onClick={() => {
                        setActiveStep(2);
                        // handleFacadeRecommendation();
                        setCompletedSteps([...completedSteps, 1]);
                      }}
                    >
                      <p>2</p>
                    </div>
                  </div>
                  <div
                    className={`stepper-line ${
                      completedSteps.includes(2) ? "greenBar" : ""
                    }`}
                  ></div>

                  <div className="stepname">
                    <span>Step 3</span>
                    <div
                      className={`box-alignment ${
                        completedSteps.includes(3) ? "active-box" : ""
                      }`}
                      onClick={() => {
                        setActiveStep(3);
                        setCompletedSteps([...completedSteps, 1, 2]);
                      }}
                    >
                      <p>3</p>
                    </div>
                  </div>
                  <div
                    className={`stepper-line ${
                      completedSteps.includes(3) ? "greenBar" : ""
                    }`}
                  ></div>

                  <div className="stepname">
                    <span>Step 4</span>
                    <div
                      className={`box-alignment ${
                        completedSteps.includes(4) ? "active-box" : ""
                      }`}
                      onClick={() => {
                        setActiveStep(4);
                        setCompletedSteps([...completedSteps, 1, 2, 3, 4]);
                      }}
                    >
                      <p>4</p>
                    </div>
                  </div>
                </div>
                <div className="stepper-content">
                  <div className="glass-output-wrapper">
                    {(layer === "Single" ||
                      layer === "Double" ||
                      layer === "Triple") && (
                      <>
                        <img
                          id="outer-glass-1"
                          src={CalcGlass}
                          alt=""
                          style={{
                            top: `${
                              outerGlassThickness.single.glass1Thickness.top +
                              "px"
                            }`,
                            left: `${
                              outerGlassThickness.single.glass1Thickness.left +
                              "px"
                            }`,
                          }}
                        />

                        {isSingleLaminated && (
                          <>
                            <img
                              id="outer-glass-laminated"
                              src={CalcGlassLaminated}
                              alt=""
                              style={{
                                top: `${
                                  outerGlassThickness.single.laminatedThickness
                                    .top + "px"
                                }`,
                                left: `${
                                  outerGlassThickness.single.laminatedThickness
                                    .left + "px"
                                }`,
                                width: "115px",
                              }}
                            />
                            <img
                              id="outer-glass-2"
                              src={CalcGlass}
                              alt=""
                              style={{
                                top: `${
                                  outerGlassThickness.single.glass2Thickness
                                    .top + "px"
                                }`,
                                left: `${
                                  outerGlassThickness.single.glass2Thickness
                                    .left + "px"
                                }`,
                              }}
                            />
                          </>
                        )}
                      </>
                    )}

                    {/* {layer === "Double" && (
                      <>
                        <img
                          id="inner-glass-1"
                          src={CalcGlass}
                          alt=""
                          style={{
                            top: `${
                              innerGlassThickness.glass1Thickness.top + "px"
                            }`,
                            left: `${
                              innerGlassThickness.glass1Thickness.left + "px"
                            }`,
                          }}
                        />

                        {isDoubleLaminated && (
                          <>
                            <img
                              id="inner-glass-laminated"
                              src={CalcGlassLaminated}
                              alt=""
                              style={{
                                top: `${
                                  innerGlassThickness.laminatedThickness.top +
                                  "px"
                                }`,
                                left: `${
                                  innerGlassThickness.laminatedThickness.left +
                                  "px"
                                }`,
                                width: "115px",
                              }}
                            />
                            <img
                              id="inner-glass-2"
                              src={CalcGlass}
                              alt=""
                              style={{
                                top: `${
                                  innerGlassThickness.glass2Thickness.top + "px"
                                }`,
                                left: `${
                                  innerGlassThickness.glass2Thickness.left +
                                  "px"
                                }`,
                              }}
                            />
                          </>
                        )}
                      </>
                    )}

                    {layer === "Triple" && (
                      <>
                        <img
                          id="inner-glass-1"
                          src={CalcGlass}
                          alt=""
                          style={{
                            top: `${
                              innerGlassThickness.glass1Thickness.top + "px"
                            }`,
                            left: `${
                              innerGlassThickness.glass1Thickness.left + "px"
                            }`,
                          }}
                        />

                        {isDoubleLaminated && (
                          <>
                            <img
                              src={CalcGlassLaminated}
                              alt=""
                              style={{
                                top: `${
                                  innerGlassThickness.laminatedThickness.top +
                                  "px"
                                }`,
                                left: `${
                                  innerGlassThickness.laminatedThickness.left +
                                  "px"
                                }`,
                                width: "115px",
                              }}
                            />
                            <img
                              src={CalcGlass}
                              alt=""
                              style={{
                                top: `${
                                  innerGlassThickness.glass2Thickness.top + "px"
                                }`,
                                left: `${
                                  innerGlassThickness.glass2Thickness.left +
                                  "px"
                                }`,
                              }}
                            />
                          </>
                        )}

                        <img
                          src={CalcGlass}
                          alt=""
                          style={{
                            top: `${
                              middleGlassThickness.glass1Thickness.top + "px"
                            }`,
                            left: `${
                              middleGlassThickness.glass1Thickness.left + "px"
                            }`,
                          }}
                        />

                        {isTripleLaminated && (
                          <>
                            <img
                              src={CalcGlassLaminated}
                              alt=""
                              style={{
                                top: `${
                                  middleGlassThickness.laminatedThickness.top +
                                  "px"
                                }`,
                                left: `${
                                  middleGlassThickness.laminatedThickness.left +
                                  "px"
                                }`,
                                width: "115px",
                              }}
                            />
                            <img
                              src={CalcGlass}
                              alt=""
                              style={{
                                top: `${
                                  middleGlassThickness.glass2Thickness.top +
                                  "px"
                                }`,
                                left: `${
                                  middleGlassThickness.glass2Thickness.left +
                                  "px"
                                }`,
                              }}
                            />
                          </>
                        )}
                      </>
                    )} */}
                  </div>
                  <div className="stepper-main-form">
                    <div className="stepper-form-alignment">
                      {activeStep === 1 && (
                        <div className="glass-input-wrapper">
                          <div className="input-group-wrapper">
                            <FormLabel>
                              Importance of natural light (Rate from 10 to 1)
                            </FormLabel>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "40px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <Slider
                                ref={sliderRef1}
                                valueLabelDisplay="auto"
                                aria-label=" slider"
                                value={11 - (formData.naturalLight || 9)}
                                max={10}
                                min={1}
                                marks={[
                                  { label: "10", value: 1 },
                                  { label: "1", value: 10 },
                                ]}
                                scale={(x) => 11 - x}
                                onChange={(event, newValue) => {
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    naturalLight: 11 - newValue,
                                  }));
                                }}
                                sx={{
                                  color: "#393185",
                                  height: 4,
                                  "& .MuiSlider-track": {
                                    border: "none",
                                  },
                                  "& .MuiSlider-thumb": {
                                    height: 15,
                                    width: 14,
                                    backgroundColor: "#fff",
                                    border: "2px solid currentColor",
                                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                      {
                                        boxShadow: "inherit",
                                      },
                                    "&::before": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    lineHeight: 1.2,
                                    fontSize: 14,
                                    background: "unset",
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50% 50% 50% 0",
                                    backgroundColor: "#393185",
                                    transformOrigin: "bottom left",
                                    transform:
                                      "translate(50%, -100%) rotate(-45deg) scale(0)",
                                    "&::before": { display: "none" },
                                    "&.MuiSlider-valueLabelOpen": {
                                      transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                    },
                                    "& > *": {
                                      transform: "rotate(45deg)",
                                    },
                                  },
                                }}
                              />
                              <button>{formData.naturalLight}</button>
                            </div>
                          </div>

                          <div className="input-group-wrapper">
                            <FormLabel>
                              Importance of saving electricity (Rate from 10 to
                              1)
                            </FormLabel>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "40px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <Slider
                                valueLabelDisplay="auto"
                                aria-label=" slider"
                                value={11 - (formData.savingElectricity || 9)}
                                max={10}
                                min={1}
                                marks={[
                                  { label: "10", value: 1 },
                                  { label: "1", value: 10 },
                                ]}
                                scale={(x) => 11 - x}
                                onChange={(event, newValue) => {
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    savingElectricity: 11 - newValue,
                                  }));
                                }}
                                sx={{
                                  color: "#393185",
                                  height: 4,
                                  "& .MuiSlider-track": {
                                    border: "none",
                                  },
                                  "& .MuiSlider-thumb": {
                                    height: 15,
                                    width: 14,
                                    backgroundColor: "#fff",
                                    border: "2px solid currentColor",
                                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                      {
                                        boxShadow: "inherit",
                                      },
                                    "&::before": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    lineHeight: 1.2,
                                    fontSize: 14,
                                    background: "unset",
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50% 50% 50% 0",
                                    backgroundColor: "#393185",
                                    transformOrigin: "bottom left",
                                    transform:
                                      "translate(50%, -100%) rotate(-45deg) scale(0)",
                                    "&::before": { display: "none" },
                                    "&.MuiSlider-valueLabelOpen": {
                                      transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                    },
                                    "& > *": {
                                      transform: "rotate(45deg)",
                                    },
                                  },
                                }}
                              />
                              <button>{formData.savingElectricity}</button>
                            </div>
                          </div>

                          <div className="input-group-wrapper">
                            <FormLabel>
                              Importance of aesthetic look (Rate from 10 to 1)
                            </FormLabel>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "40px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <Slider
                                valueLabelDisplay="auto"
                                aria-label=" slider"
                                value={11 - (formData.asetheticLook || 9)}
                                max={10}
                                min={1}
                                marks={[
                                  { label: "10", value: 1 },
                                  { label: "1", value: 10 },
                                ]}
                                scale={(x) => 11 - x}
                                onChange={(event, newValue) => {
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    asetheticLook: 11 - newValue,
                                  }));
                                }}
                                sx={{
                                  color: "#393185",
                                  height: 4,
                                  "& .MuiSlider-track": {
                                    border: "none",
                                  },
                                  "& .MuiSlider-thumb": {
                                    height: 15,
                                    width: 14,
                                    backgroundColor: "#fff",
                                    border: "2px solid currentColor",
                                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                      {
                                        boxShadow: "inherit",
                                      },
                                    "&::before": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    lineHeight: 1.2,
                                    fontSize: 14,
                                    background: "unset",
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50% 50% 50% 0",
                                    backgroundColor: "#393185",
                                    transformOrigin: "bottom left",
                                    transform:
                                      "translate(50%, -100%) rotate(-45deg) scale(0)",
                                    "&::before": { display: "none" },
                                    "&.MuiSlider-valueLabelOpen": {
                                      transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                    },
                                    "& > *": {
                                      transform: "rotate(45deg)",
                                    },
                                  },
                                }}
                              />
                              <button>{formData.asetheticLook}</button>
                            </div>
                          </div>

                          <div className="input-group-wrapper">
                            <FormLabel>
                              Importance of sound proofing (Rate from 10 to 1)
                            </FormLabel>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "40px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <Slider
                                valueLabelDisplay="auto"
                                aria-label=" slider"
                                value={11 - (formData.soundProofing || 9)}
                                max={10}
                                min={1}
                                marks={[
                                  { label: "10", value: 1 },
                                  { label: "1", value: 10 },
                                ]}
                                scale={(x) => 11 - x}
                                onChange={(event, newValue) => {
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    soundProofing: 11 - newValue,
                                  }));
                                }}
                                sx={{
                                  color: "#393185",
                                  height: 4,
                                  "& .MuiSlider-track": {
                                    border: "none",
                                  },
                                  "& .MuiSlider-thumb": {
                                    height: 15,
                                    width: 14,
                                    backgroundColor: "#fff",
                                    border: "2px solid currentColor",
                                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                      {
                                        boxShadow: "inherit",
                                      },
                                    "&::before": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    lineHeight: 1.2,
                                    fontSize: 14,
                                    background: "unset",
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50% 50% 50% 0",
                                    backgroundColor: "#393185",
                                    transformOrigin: "bottom left",
                                    transform:
                                      "translate(50%, -100%) rotate(-45deg) scale(0)",
                                    "&::before": { display: "none" },
                                    "&.MuiSlider-valueLabelOpen": {
                                      transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                    },
                                    "& > *": {
                                      transform: "rotate(45deg)",
                                    },
                                  },
                                }}
                              />
                              <button>{formData.soundProofing}</button>
                            </div>
                          </div>

                          <div className="input-group-wrapper">
                            <FormLabel>
                              Importance of security (Rate from 10 to 1)
                            </FormLabel>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "40px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <Slider
                                valueLabelDisplay="auto"
                                aria-label=" slider"
                                value={11 - (formData.safety || 9)}
                                max={10}
                                min={1}
                                marks={[
                                  { label: "10", value: 1 },
                                  { label: "1", value: 10 },
                                ]}
                                scale={(x) => 11 - x}
                                onChange={(event, newValue) => {
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    safety: 11 - newValue,
                                  }));
                                  setTimeout(() => {
                                    setActiveStep(2);
                                    setCompletedSteps([...completedSteps, 1]);
                                  }, 2000);
                                }}
                                sx={{
                                  color: "#393185",
                                  height: 4,
                                  "& .MuiSlider-track": {
                                    border: "none",
                                  },
                                  "& .MuiSlider-thumb": {
                                    height: 15,
                                    width: 14,
                                    backgroundColor: "#fff",
                                    border: "2px solid currentColor",
                                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible":
                                      {
                                        boxShadow: "inherit",
                                      },
                                    "&::before": {
                                      display: "none",
                                    },
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    lineHeight: 1.2,
                                    fontSize: 14,
                                    background: "unset",
                                    padding: 0,
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50% 50% 50% 0",
                                    backgroundColor: "#393185",
                                    transformOrigin: "bottom left",
                                    transform:
                                      "translate(50%, -100%) rotate(-45deg) scale(0)",
                                    "&::before": { display: "none" },
                                    "&.MuiSlider-valueLabelOpen": {
                                      transform:
                                        "translate(50%, -100%) rotate(-45deg) scale(1)",
                                    },
                                    "& > *": {
                                      transform: "rotate(45deg)",
                                    },
                                  },
                                }}
                              />
                              <button>{formData.safety}</button>
                            </div>
                          </div>
                        </div>
                      )}

                      {activeStep === 2 && (
                        <div className="step-form-alignment">
                          <div className="top-form-alignment">
                            <div className="step-input-alignment">
                              <FormLabel>
                                Does Your Area Encounters with storms or
                                Cyclones? (Y?N)
                              </FormLabel>

                              <div className="input-details">
                                <RadioGroup
                                  name="radio-buttons-group"
                                  className="radio-btn-group"
                                  value={formData.isStormCyclone || "No"}
                                  onChange={(event) => {
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      isStormCyclone: event.target.value,
                                    }));
                                  }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </div>
                            </div>

                            <div className="step-input-alignment">
                              <FormLabel>
                                In which zone is your building placed?
                              </FormLabel>

                              <div className="input-details">
                                <RadioGroup
                                  name="radio-buttons-group"
                                  value={formData.soundZone}
                                  onChange={(event) => {
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      soundZone: event.target.value,
                                    }));
                                  }}
                                >
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    style={{
                                      marginBottom: "10px",
                                      alignItems: "flex-start",
                                    }}
                                    label="Low Frequency Sound Zone (Ex- Urban Traffic, People Talking, TV, Radio, Gun Shot, Disco, Music)"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    style={{
                                      marginBottom: "10px",
                                      alignItems: "flex-start",
                                    }}
                                    label="High Frequency Sound Zone (Airplanes, Fast moving Trains, Factories, Continuous Traffic, Sound of Truck Engines)"
                                  />
                                  <FormControlLabel
                                    value="3"
                                    control={<Radio />}
                                    label="Comparatively Peaceful Area With Very Less Noise"
                                  />
                                </RadioGroup>
                              </div>
                            </div>

                            <div className="step-grid">
                              <div className="step-input-alignment">
                                <FormLabel>
                                  Select nearby Town/City where the property is
                                  Located
                                </FormLabel>

                                <div className="input-details">
                                  <select
                                    value={selectedCity}
                                    onChange={(e) =>
                                      handleCityChange(e.target.value)
                                    }
                                  >
                                    {citiesData?.map((city, index) => (
                                      <option key={index} value={city}>
                                        {city}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="step-input-alignment">
                                <FormLabel>
                                  Average Exterior Temperature <br />
                                  (As per Google)
                                </FormLabel>

                                <div className="input-details">
                                  <div className="input-mask-wrapper">
                                    <input
                                      type="text"
                                      value={weatherValue}
                                      disabled
                                    />
                                    <button>°C</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {activeStep === 3 && (
                        <div className="step-form-alignment">
                          <div className="top-form-alignment">
                            <div className="step-grid">
                              <div className="step-input-alignment">
                                <FormLabel>
                                  Average Interior Temperature (AC Temp required
                                  to be maintained)
                                </FormLabel>

                                <div className="input-details">
                                  <div className="input-mask-wrapper">
                                    <input
                                      type="text"
                                      value={formData.interiorTemp}
                                      onChange={(event) => {
                                        setFormData((prevFormData) => ({
                                          ...prevFormData,
                                          interiorTemp: event.target.value,
                                        }));
                                      }}
                                    />
                                    <button>°C</button>
                                  </div>
                                </div>
                              </div>

                              <div className="step-input-alignment">
                                <FormLabel>
                                  Cost of Electricity per Unit <br />
                                  &nbsp;&nbsp;
                                </FormLabel>

                                <div className="input-details">
                                  <input
                                    type="number"
                                    value={formData.electricityCost}
                                    onChange={(event) => {
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        electricityCost: event.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="step-grid">
                              <div className="step-input-alignment">
                                <FormLabel>
                                  Approximate number of hours of AC usage per
                                  Day
                                </FormLabel>

                                <div className="input-details">
                                  <input
                                    type="number"
                                    value={formData.hrsACPerDay}
                                    onChange={(event) => {
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        hrsACPerDay: event.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="step-input-alignment">
                                <FormLabel>
                                  Approximate number of days of AC use per Annum
                                </FormLabel>

                                <div className="input-details">
                                  <input
                                    type="number"
                                    value={formData.hrsACPerAnnum}
                                    onChange={(event) => {
                                      setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        hrsACPerAnnum: event.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="step-input-alignment">
                              <FormLabel>Major Facade Facing?</FormLabel>

                              <div className="input-details">
                                <RadioGroup
                                  defaultValue="1"
                                  name="radio-buttons-group"
                                  className="radio-btn-group"
                                  value={formData.facadeFacing}
                                  onChange={(event) => {
                                    setFormData((prevFormData) => ({
                                      ...prevFormData,
                                      facadeFacing: event.target.value,
                                    }));
                                    setTimeout(() => {
                                      setActiveStep(4);
                                      setCompletedSteps([
                                        ...completedSteps,
                                        3,
                                        4,
                                      ]);
                                    }, 2000);
                                  }}
                                >
                                  <FormControlLabel
                                    value="S"
                                    control={<Radio />}
                                    label="South"
                                  />
                                  <FormControlLabel
                                    value="W"
                                    control={<Radio />}
                                    label="West"
                                  />
                                  <FormControlLabel
                                    value="N"
                                    control={<Radio />}
                                    label="North"
                                  />
                                  <FormControlLabel
                                    value="E"
                                    control={<Radio />}
                                    label="East"
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                            <div className="step-input-alignment">
                              <FormLabel
                                sx={{
                                  fontWeight: "600",
                                }}
                              >
                                Facade Recommendation
                              </FormLabel>

                              <div className="input-details">
                                <div className="recommendation-wrapper">
                                  <span>{facadeRecommended}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {activeStep === 4 && (
                        <div className="step-form-alignment">
                          <Accordion defaultExpanded>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              Dimension of Glasses in South (In Feet)
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                maxHeight: "500px",
                                overflow: "auto",
                              }}
                            >
                              <SouthFacade
                                formData={formData}
                                setLayer={setLayer}
                                setIsSingleLaminated={setIsSingleLaminated}
                                setIsDoubleLaminated={setIsDoubleLaminated}
                                setIsTripleLaminated={setIsTripleLaminated}
                                handleSingleGlass={handleSingleGlass}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              Dimension of Glasses in West (In Feet)
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                maxHeight: "500px",
                                overflow: "auto",
                              }}
                            >
                              <WestFacade
                                formData={formData}
                                setLayer={setLayer}
                                setIsSingleLaminated={setIsSingleLaminated}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              Dimension of Glasses in North (In Feet)
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                maxHeight: "500px",
                                overflow: "auto",
                              }}
                            >
                              <NorthFacade
                                formData={formData}
                                setLayer={setLayer}
                                setIsSingleLaminated={setIsSingleLaminated}
                              />
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              Dimension of Glasses in East (In Feet)
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                maxHeight: "500px",
                                overflow: "auto",
                              }}
                            >
                              <EastFacade
                                formData={formData}
                                setLayer={setLayer}
                                setIsSingleLaminated={setIsSingleLaminated}
                              />
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Calculator;
